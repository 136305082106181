import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { useMemberProfileProgress } from '../../hooks/useMemberProfileProgress';

import { useMember } from '.';

const RATING_DICT: Record<string, string> = {
    passing: 'Passing',
    shooting: 'Shooting',
    dribbling: 'Dribbling',
    gameAwareness: 'Awareness',
    score: 'Overall',
};

const MemberPrgoressChart: React.FC = () => {
    const { _id: id } = useMember();
    const { data } = useMemberProfileProgress(id);

    const chartData = useMemo(() => {
        return data?.member.progress.map((rating) => {
            const time = dayjs(`${rating.year}-${rating.month + 1}`, 'YYYY-M');
            return {
                ...rating,
                time: time.valueOf(),
            };
        });
    }, [data]);

    if (!data?.member.progress.length) return null;

    return (
        <ResponsiveContainer width="100%" height={200}>
            <LineChart
                style={{ fontSize: '0.75rem' }}
                data={chartData}
                margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
            >
                <XAxis
                    interval={0}
                    dataKey="time"
                    type="number"
                    domain={['auto', 'auto']}
                    scale="time"
                    tickFormatter={(value) => dayjs(value).format('MMM')}
                />
                <YAxis />
                <Tooltip labelFormatter={(value) => dayjs(value).format('MMM YYYY')} />
                <CartesianGrid strokeDasharray="3 3" />

                <Legend formatter={(value) => RATING_DICT[value]} wrapperStyle={{ width: '100%' }} />

                <Line type="monotone" dataKey={'gameAwareness'} stroke="#d9ed92" strokeOpacity={0.4} />
                <Line type="monotone" dataKey={'dribbling'} stroke="#76c893" strokeOpacity={0.4} />
                <Line type="monotone" dataKey={'passing'} stroke="#168aad" strokeOpacity={0.4} />

                <Line type="monotone" dataKey={'shooting'} stroke="#184e77" strokeOpacity={0.4} />

                <Line type="monotone" dataKey={'score'} stroke="#be2039" />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default MemberPrgoressChart;
