import styled from '@emotion/styled';
import { CheckboxChangeEventDetail } from '@ionic/core';
import {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { close, filter as filterIcon } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';

import { FilterState } from '../../pages/Members';

enum Filter {
    AGE = 'age',
    GENDER = 'gender',
}

const filterOptions = {
    age: ['Under 12', 'Under 17', 'Other'],
    gender: ['Male', 'Female'],
};

type Props = {
    value?: FilterState;
    handleSubmit: (input: any) => void;
};

const initialFilter: FilterState = {
    age: [],
    gender: [],
    numTokensLt5: false,
};

const MembersFilter: React.FC<Props> = ({ value, handleSubmit }) => {
    const [show, setShow] = useState<boolean>(false);
    const [filter, setFilter] = useImmer<FilterState>(initialFilter);

    const isFiltered = value && value !== initialFilter;

    const handleChange = (field: Filter, option: string) => (e: CustomEvent<CheckboxChangeEventDetail>) => {
        setFilter((draft) => {
            if (e.detail.checked) {
                draft[field].push(option);
            } else {
                draft[field].splice(draft.age.indexOf(option), 1);
            }
        });
    };

    useEffect(() => {
        if (value) {
            setFilter(() => value);
        }
    }, [setFilter, value]);

    return (
        <>
            <Button fill={isFiltered ? 'solid' : 'clear'} onClick={() => setShow(true)}>
                <IonIcon slot="icon-only" icon={filterIcon} />
            </Button>
            <IonModal isOpen={show}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="primary">
                            <IonButton onClick={() => setShow(false)}>
                                <IonIcon icon={close} />
                            </IonButton>
                        </IonButtons>
                        <IonTitle>Filters</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">Filters</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonList>
                        <IonListHeader>Age Group</IonListHeader>
                        {filterOptions.age.map((option) => (
                            <IonItem key={option}>
                                <IonLabel>{option}</IonLabel>
                                <IonCheckbox
                                    slot="end"
                                    value={option}
                                    checked={filter.age.includes(option)}
                                    onIonChange={handleChange(Filter.AGE, option)}
                                />
                            </IonItem>
                        ))}
                    </IonList>
                    <IonList>
                        <IonListHeader>Gender</IonListHeader>
                        {filterOptions.gender.map((option) => (
                            <IonItem key={option}>
                                <IonLabel>{option}</IonLabel>
                                <IonCheckbox
                                    slot="end"
                                    value={option}
                                    checked={filter.gender.includes(option)}
                                    onIonChange={handleChange(Filter.GENDER, option)}
                                />
                            </IonItem>
                        ))}
                    </IonList>
                    <IonList>
                        <IonListHeader>Remaining Session</IonListHeader>
                        <IonItem>
                            <IonLabel>Less than 5</IonLabel>
                            <IonCheckbox
                                slot="end"
                                value="Less than 5"
                                checked={filter.numTokensLt5}
                                onIonChange={(e) => {
                                    setFilter((filter) => {
                                        filter.numTokensLt5 = e.detail.checked;
                                    });
                                }}
                            />
                        </IonItem>
                    </IonList>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <ButtonsContainer>
                            <IonButton
                                fill="clear"
                                onClick={() => {
                                    setFilter(() => initialFilter);
                                    handleSubmit(initialFilter);
                                    setShow(false);
                                }}
                            >
                                Clear
                            </IonButton>
                            <IonButton
                                style={{ marginLeft: '1rem', width: '100%' }}
                                onClick={() => {
                                    handleSubmit(filter);
                                    setShow(false);
                                }}
                            >
                                Apply Filters
                            </IonButton>
                        </ButtonsContainer>
                    </IonToolbar>
                </IonFooter>
            </IonModal>
        </>
    );
};

export default MembersFilter;

const Button = styled(IonButton)`
    margin: 0;
    font-size: smaller;
`;

const ButtonsContainer = styled.div`
    display: flex;

    & > ion-button:last-of-type {
        flex: 1;
    }
`;
