/** @jsxImportSource @emotion/react */

import { IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonList, IonPage, IonToolbar } from '@ionic/react';
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';

import CatalogueDropdown from '../features/Catalogues/CatalogueDropdown';
import { useCatalogues } from '../hooks/useCatalogues';

const TrainingVideos: React.FC = (): React.ReactElement => {
    const { data } = useCatalogues();
    const [catalogues, setCatalogues] = useState<Catalogue[]>([]);

    useEffect(() => {
        if (data?.catalogues) {
            setCatalogues(data.catalogues);
        }
    }, [data]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent
                css={css`
                    --padding-bottom: 2rem;
                `}
            >
                <IonList>
                    {!catalogues.length ? (
                        <IonItem>
                            <h1>Empty Catalogue</h1>
                        </IonItem>
                    ) : (
                        catalogues.map((catalogue) => <CatalogueDropdown key={catalogue._id} catalogue={catalogue} />)
                    )}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default TrainingVideos;
