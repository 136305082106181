import styled from '@emotion/styled';

export const Upload = styled.input`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    z-index: 2;
`;
