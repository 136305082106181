import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const UPLOAD_PAST_GAME = gql`
    mutation UploadPastGame($input: UploadPastGameInput!) {
        uploadPastGame(input: $input) {
            _id
            url
        }
    }
`;

type TData = { uploadPastGame: PastGame };
type TVariables = { input: { url: string } };

export const useUploadPastGame = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(UPLOAD_PAST_GAME, options);
};
