import React, { useEffect } from 'react';

export const useScrollToTop = () => {
    const ref = React.createRef<HTMLIonContentElement>();

    useEffect(() => {
        ref.current && ref.current.scrollToTop && ref.current.scrollToTop();
    }, [ref]);

    return ref;
};
