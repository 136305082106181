/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { IonActionSheet, IonIcon, IonItem, IonItemDivider, IonLabel, IonList } from '@ionic/react';
import dayjs from 'dayjs';
import { calendarOutline, callOutline, receiptOutline, statsChartOutline } from 'ionicons/icons';
import get from 'lodash/get';
import React, { useState } from 'react';

import { useModal } from '../../modals';

import { useMember } from '.';

const MemberDetails: React.FC = () => {
    const profile = useMember();
    const { toggle } = useModal();

    const MEMBER_FIELDS = [
        {
            key: 'gender',
            label: 'Gender',
            dataIndex: 'gender',
        },
        {
            key: 'dob',
            label: 'Date of Birth',
            dataIndex: 'birthdate',
            children: dayjs(profile.birthdate).format('DD/MM/YYYY'),
        },
        {
            key: 'jerseySize',
            label: 'Jersey Size',
            dataIndex: 'jerseySize',
        },
        {
            key: 'experience',
            label: 'Years of Experience',
            dataIndex: 'experience',
        },
    ];

    const ACCOUNT_FIELDS = [
        {
            key: 'name',
            label: 'Contact Name',
            dataIndex: ['account', 'name'],
        },
        {
            key: 'email',
            label: 'Email',
            dataIndex: ['account', 'email'],
        },
        {
            key: 'phoneNumber',
            label: 'Phone',
            dataIndex: ['account', 'phoneNumber'],
            children: <PhoneNumber {...{ profile }} />,
        },
    ];

    return (
        <>
            <IonList>
                <IonItemDivider />

                <IonItem button onClick={() => toggle('showMemberAttendances')}>
                    <IonIcon color="primary" size="small" icon={calendarOutline} slot="start" />
                    <IonLabel>Attendance</IonLabel>
                </IonItem>

                <IonItem button onClick={() => toggle('showMemberPerformance')}>
                    <IonIcon color="primary" size="small" icon={statsChartOutline} slot="start" />
                    <IonLabel>Monthly Performance</IonLabel>
                </IonItem>

                <IonItem lines="none" button onClick={() => toggle('showUpdateLoyaltyPointForm')}>
                    <IonIcon color="primary" size="small" icon={receiptOutline} slot="start" />
                    <IonLabel>Loyalty Points</IonLabel>
                </IonItem>
            </IonList>

            <IonItemDivider />

            {ACCOUNT_FIELDS.map(({ key, dataIndex, children, label }) => {
                return (
                    <IonItem key={key}>
                        <IonLabel>{label}</IonLabel>
                        {children || get(profile, dataIndex)}
                    </IonItem>
                );
            })}

            <IonItem button detail={false} lines="none" onClick={() => toggle('showEditMemberAccountDetails')}>
                <Text color="tertiary">Edit Account Details</Text>
            </IonItem>

            <IonItemDivider />

            {MEMBER_FIELDS.map(({ key, dataIndex, children, label }) => {
                return (
                    <IonItem key={key}>
                        <IonLabel>{label}</IonLabel>
                        {children || get(profile, dataIndex)}
                    </IonItem>
                );
            })}

            <IonItem button detail={false} lines="none" onClick={() => toggle('showEditMemberDetails')}>
                <Text color="tertiary">Edit Member Details</Text>
            </IonItem>

            <IonItemDivider />
        </>
    );
};

export default MemberDetails;

const Text = styled(IonLabel)`
    text-align: center;
`;

const PhoneNumber: React.FC<{ profile: MemberProfile }> = ({ profile }) => {
    const [show, setShow] = useState(false);
    const phoneNumber = get(profile, ['account', 'phoneNumber']);

    return (
        <>
            {phoneNumber}
            <IonIcon slot="end" icon={callOutline} size="small" onClick={() => setShow(true)} />
            <IonActionSheet
                isOpen={show}
                onDidDismiss={() => setShow(false)}
                buttons={[
                    {
                        text: `Call ${phoneNumber}`,
                        handler: () => {
                            window.open(`tel:${phoneNumber}`);
                        },
                    },
                    {
                        text: `Text ${phoneNumber}`,
                        handler: () => {
                            window.open(`sms:${phoneNumber}`);
                        },
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                ]}
            />
        </>
    );
};
