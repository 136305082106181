import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const REQUEST_REMAINDER_AMOUNT = gql`
    mutation RequestRemainderAmount($id: String!) {
        requestRemainderAmount(id: $id) {
            _id
            status
        }
    }
`;

type TVariables = { id: string };
type TData = { requestRemainderAmount: Pick<PaymentApproval, '_id' | 'status'> };

export const useRequestRemainderAmount = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(REQUEST_REMAINDER_AMOUNT, options);
};
