/** @jsxImportSource @emotion/react */

import { Toast } from '@capacitor/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    IonIcon,
    IonItem,
    IonItemGroup,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonTextarea,
} from '@ionic/react';
import { chevronDownOutline, chevronUpOutline, trash } from 'ionicons/icons';
import React, { useState } from 'react';

import { useRemoveSidebar } from '../../hooks/useRemoveSidebar';
import { useUpdateSidebar } from '../../hooks/useUpdateSidebar';

interface SidebarInputProps {
    label: string;
    input: string;
    onUpdateInputHandler: (inputType: string, updateInput: string) => void;
}

const SidebarInput: React.FunctionComponent<SidebarInputProps> = ({ label, input, onUpdateInputHandler }) => {
    const [updateInput, setUpdateInput] = useState(input);
    const handleOnUpdateInput = () => {
        if (updateInput === input) return;
        onUpdateInputHandler(label.toLowerCase(), updateInput);
    };
    return (
        <IonItem>
            <IonLabel position="stacked">{label}</IonLabel>
            <IonTextarea
                autoGrow
                value={updateInput}
                onIonChange={(e) => setUpdateInput(e.detail.value || '')}
                onIonBlur={handleOnUpdateInput}
            />
        </IonItem>
    );
};
interface SidebarDetailProps {
    sidebar: Sidebar;
    onRemoveSidebar: (SidebarId: string) => void;
    onUpdateSidebar: (Sidebar: Sidebar) => void;
}

const SidebarDetail: React.FunctionComponent<SidebarDetailProps> = ({ sidebar, onRemoveSidebar, onUpdateSidebar }) => {
    const { _id, title, link } = sidebar;
    const [isDropped, setIsDropped] = useState<boolean>(false);
    const [removeSidebar] = useRemoveSidebar({
        onCompleted: (data) => {
            onRemoveSidebar(data.removeSidebar._id);
            Toast.show({ text: 'Delete successfully' });
        },
        onError(err) {
            Toast.show({ text: err.message });
        },
    });

    const [updateSidebar] = useUpdateSidebar({
        onCompleted: (data) => {
            onUpdateSidebar(data.updateSidebar);
            Toast.show({ text: 'Update successfully' });
        },
        onError: (err) => {
            Toast.show({ text: err.message });
        },
    });

    const handleOnUpdateSidebar = (key: string, value: string) => {
        if (!value.length) {
            Toast.show({ text: 'Fields are required' });
            return;
        }
        updateSidebar({ variables: { id: sidebar._id, input: { key, value } } });
    };

    return (
        <IonItemGroup>
            <IonItemSliding
                css={css`
                    display: inline-flex;
                `}
            >
                <IonItemOptions side="end">
                    <IonItemOption onClick={() => removeSidebar({ variables: { id: _id } })}>
                        <IonIcon icon={trash} slot="icon-only" />
                    </IonItemOption>
                </IonItemOptions>

                <HeaderItem className="ion-activatable ripple-parent" onClick={() => setIsDropped(!isDropped)}>
                    <div>{title}</div>
                    <IonIcon size="small" color="sliver" icon={isDropped ? chevronUpOutline : chevronDownOutline} />
                </HeaderItem>
            </IonItemSliding>

            {isDropped && (
                <ContentContainer>
                    <SidebarInput label="Title" input={title} onUpdateInputHandler={handleOnUpdateSidebar} />
                    <SidebarInput label="Link" input={link} onUpdateInputHandler={handleOnUpdateSidebar} />
                </ContentContainer>
            )}
        </IonItemGroup>
    );
};

export default SidebarDetail;

const HeaderItem = styled(IonItem)`
    width: 100%;
    div {
        width: 100%;
    }
    ion-icon {
        float: right;
    }
`;

const ContentContainer = styled.div`
    p {
        margin-left: 2rem;
    }
`;
