/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import {
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonLoading,
    IonTextarea,
    IonToast,
} from '@ionic/react';
import { chevronDownOutline, chevronUpOutline, trash } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';

import { Upload } from '../../components';
import useS3Upload from '../../hooks/useS3Upload';
import { useVideos } from '../../hooks/useVideos';
import { GET_LIST_COMPETITIONS } from '../../hooks/useCompetitions';
import { useRemoveCompetition } from '../../hooks/useRemoveCompetition';
import { useUpdateCompetition } from '../../hooks/useUpdateCompetition';

import CompetitionBlock from './CompetitionBlock';

interface CompetitionDropdownProps {
    competition: Competition;
}

interface Toast {
    isOpen: boolean;
    message: string;
    color?: string;
}

const CompetitionDropdown: React.FunctionComponent<CompetitionDropdownProps> = ({ competition }) => {
    const [isDropped, setIsDropped] = useState<boolean>(false);
    const [toast, setToast] = useState<Toast>({ isOpen: false, message: '' });
    const [descriptionValue, setDescriptionValue] = useState<string>(competition.description);
    const [videoDemo, setVideoDemo] = useState<string>(competition.videoDemo);
    const [competitionThumbnail, setCompetitionThumbnail] = useState<string>(competition.thumbnail);
    const [loading, setLoading] = useState(false);
    const [getVideos, { data }] = useVideos({
        variables: { isShow: false, competitionId: competition._id, isLimit: true },
    });

    const [updateCompetition] = useUpdateCompetition({
        onCompleted: (data) => {
            setVideoDemo(data.updateCompetition.videoDemo);
            setCompetitionThumbnail(data.updateCompetition.thumbnail);
            setLoading(false);
            setToast({ isOpen: true, message: 'Uploaded Successfully!', color: 'success' });
        },
        onError: () => {
            setLoading(false);
            setToast({ isOpen: true, message: 'Something went wrong. Please try again' });
        },
    });

    const [removeCompetition] = useRemoveCompetition({
        onError: () => {
            setToast({ isOpen: true, message: 'Something went wrong. Please try again' });
        },
        update: (cache, { data }) => {
            const existedList = cache.readQuery<{ competitions: Competition[] }>({
                query: GET_LIST_COMPETITIONS,
            });
            if (data?.removeCompetition && existedList) {
                cache.writeQuery<{ competitions: Competition[] }>({
                    query: GET_LIST_COMPETITIONS,
                    data: {
                        competitions: existedList.competitions.filter(
                            (competition) => competition._id !== data.removeCompetition._id,
                        ),
                    },
                });
            }
        },
    });

    useEffect(() => {
        if (isDropped && !data?.videos.length) {
            getVideos();
        }
    }, [isDropped]);

    const handleOnUpdateDescription = () => {
        if (descriptionValue === competition.description) return;
        setLoading(true);
        updateCompetition({
            variables: { id: competition._id, input: { name: 'description', value: descriptionValue } },
        });
    };

    const handleOnRemoveCompetition = () => {
        removeCompetition({ variables: { id: competition._id } });
    };

    const handleUploadVideoDemo = useS3Upload({
        onCompleted(filename) {
            updateCompetition({ variables: { id: competition._id, input: { name: 'videoDemo', value: filename } } });
        },
        onError(err) {
            setLoading(false);
            err.message && setToast({ message: err.message, isOpen: true });
        },
    });

    const handleUploadCompetitionThumbnail = useS3Upload({
        onCompleted(filename) {
            updateCompetition({ variables: { id: competition._id, input: { name: 'thumbnail', value: filename } } });
        },
        onError(err) {
            setLoading(false);
            err.message && setToast({ message: err.message, isOpen: true });
        },
    });

    return (
        <IonItemGroup>
            <IonToast
                duration={3000}
                color={toast.color}
                isOpen={toast.isOpen}
                onDidDismiss={() => setToast({ message: '', isOpen: false })}
                message={toast.message}
            />
            <IonItemSliding>
                <IonItemOptions side="end">
                    <IonItemOption onClick={handleOnRemoveCompetition}>
                        <IonIcon icon={trash} slot="icon-only" />
                    </IonItemOption>
                </IonItemOptions>
                <HeaderItem className="ion-activatable ripple-parent" onClick={() => setIsDropped(!isDropped)}>
                    <div>{competition.name}</div>
                    <IonIcon size="small" color="sliver" icon={isDropped ? chevronUpOutline : chevronDownOutline} />
                </HeaderItem>
            </IonItemSliding>

            {isDropped && (
                <ContentContainer>
                    <IonLoading isOpen={loading} />

                    <CompetitionInput>
                        <IonLabel position={videoDemo ? 'floating' : 'fixed'}>Demo Video</IonLabel>
                        <IonInput value={videoDemo} readonly />

                        <Upload
                            type="file"
                            accept="video/*"
                            onChange={(e) => {
                                setLoading(true);
                                handleUploadVideoDemo(e);
                            }}
                        />
                    </CompetitionInput>
                    <CompetitionInput>
                        <IonLabel position={competitionThumbnail ? 'floating' : 'fixed'}>
                            Competition Thumbnail
                        </IonLabel>
                        <IonInput value={competitionThumbnail} readonly />

                        <Upload
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                setLoading(true);
                                handleUploadCompetitionThumbnail(e);
                            }}
                        />
                    </CompetitionInput>
                    <CompetitionInput>
                        <IonLabel position="stacked">Description</IonLabel>
                        <IonTextarea
                            autoGrow
                            value={descriptionValue}
                            onIonChange={(e) => setDescriptionValue(e.detail.value || '')}
                            onIonBlur={handleOnUpdateDescription}
                        ></IonTextarea>
                    </CompetitionInput>
                    {data?.videos.length ? (
                        <>
                            {data.videos.map((video, index) => (
                                <CompetitionBlock key={index} title={`Member ${video?.userId?.name}`} video={video} />
                            ))}
                        </>
                    ) : (
                        <p>No video</p>
                    )}
                </ContentContainer>
            )}
        </IonItemGroup>
    );
};

export default CompetitionDropdown;

const HeaderItem = styled(IonItem)`
    div {
        width: 100%;
    }
    ion-icon {
        float: right;
    }
`;

const ContentContainer = styled.div`
    p {
        margin-left: 2rem;
    }
`;
const CompetitionInput = styled(IonItem)``;
