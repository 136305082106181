/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonLoading,
    IonPage,
    IonTitle,
    IonToast,
    IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';

import ProgramDropdown from '../features/ManagePrograms/ProgramDropdown';
import { usePrograms } from '../hooks/usePrograms';
import CreateProgramModal from '../modals/CreateProgramModal';

export interface ToastProps {
    isOpen: boolean;
    message: string;
    color: string;
}

const ManagePrograms: React.FunctionComponent = () => {
    const [programs, setPrograms] = useState<Program[]>([]);
    const [isOpenLoading, setIsOpenLoading] = useState(false);
    const [toast, setToast] = useState<ToastProps>({ isOpen: false, message: '', color: '' });

    useEffect(() => {
        setIsOpenLoading(true);
    }, []);

    usePrograms({
        onCompleted: (data) => {
            setIsOpenLoading(false);
            setPrograms(data.programs);
        },
        onError: () => {
            setIsOpenLoading(false);
            setToast({ isOpen: true, message: 'Something went wrong. Please try again', color: 'primary' });
        },
    });

    const handleOnCreateProgram = (newProgram: Program) => {
        setPrograms([...programs, newProgram]);
    };

    const handleOnRemoveProgram = (programId: string) => {
        setPrograms(programs.filter((program) => program._id !== programId));
    };

    const handleOnUpdateProgram = (updatedProgram: Program) => {
        const updatedPrograms = programs.map((program) => {
            if (program._id === updatedProgram._id) {
                return updatedProgram;
            }
            return program;
        });
        setPrograms(updatedPrograms);
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Manage Programs</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent
                css={css`
                    --padding-bottom: 2rem;
                `}
            >
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Manage Programs</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <CreateProgramModal onCreatedProgram={handleOnCreateProgram} setToast={setToast} />
                <IonLoading isOpen={isOpenLoading} />
                <IonList>
                    {!programs.length ? (
                        <IonItem>
                            <h1>Empty Program</h1>
                        </IonItem>
                    ) : (
                        <>
                            <IonItem lines="full">
                                <h3>Session Program</h3>
                            </IonItem>
                            {programs
                                .filter((program) => program.type === 'session')
                                .map((program) => (
                                    <ProgramDropdown
                                        key={program._id}
                                        program={program}
                                        setIsOpenLoading={setIsOpenLoading}
                                        onRemoveProgram={handleOnRemoveProgram}
                                        onUpdateProgram={handleOnUpdateProgram}
                                        setToast={setToast}
                                    />
                                ))}
                            <IonItem lines="full">
                                <h3>Monthly Program</h3>
                            </IonItem>
                            {programs
                                .filter((program) => program.type === 'monthly')
                                .map((program) => (
                                    <ProgramDropdown
                                        key={program._id}
                                        program={program}
                                        setIsOpenLoading={setIsOpenLoading}
                                        onRemoveProgram={handleOnRemoveProgram}
                                        onUpdateProgram={handleOnUpdateProgram}
                                        setToast={setToast}
                                    />
                                ))}
                        </>
                    )}
                </IonList>
                <IonToast
                    duration={3000}
                    color={toast.color}
                    isOpen={toast.isOpen}
                    onDidDismiss={() => setToast({ message: '', isOpen: false, color: '' })}
                    message={toast.message}
                />
            </IonContent>
        </IonPage>
    );
};

export default ManagePrograms;
