/** @jsxImportSource @emotion/react */

import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';

import CreateCompetitionModal from '../modals/CreateCompetitionModal';
import CompetitionDropdown from '../features/OnlineCompetitions/CompetitionDropdown';
import { useCompetitions } from '../hooks/useCompetitions';
import CompetitionUploadedVideo from '../features/OnlineCompetitions/CompetitionUploadedVideo';

const OnlineCompetitions: React.FC = (): React.ReactElement => {
    const { data } = useCompetitions();
    const [competitions, setCompetitions] = useState<Competition[]>([]);

    useEffect(() => {
        if (data?.competitions) {
            setCompetitions(data.competitions);
        }
    }, [data]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Online Competitions</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent
                css={css`
                    --padding-bottom: 2rem;
                `}
            >
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Online Competitions</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <CreateCompetitionModal />
                <IonList>
                    {!competitions.length ? (
                        <IonItem>
                            <h1>Empty Competition</h1>
                        </IonItem>
                    ) : (
                        <>
                            {competitions.map((competition) => (
                                <CompetitionDropdown key={competition._id} competition={competition} />
                            ))}
                            <IonItem lines="none">
                                <h3>Uploaded Video</h3>
                            </IonItem>
                            {competitions.map((competition) => (
                                <CompetitionUploadedVideo key={competition._id} competition={competition} />
                            ))}
                        </>
                    )}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default OnlineCompetitions;
