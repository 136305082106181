/** @jsxImportSource @emotion/react */
import { Plugins } from '@capacitor/core';
import styled from '@emotion/styled';
import {
    IonButton,
    IonContent,
    IonDatetime,
    IonFooter,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonNote,
    IonSpinner,
    IonTextarea,
} from '@ionic/react';
import { Storage } from 'aws-amplify';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { chevronDown, cloudDownloadOutline, cloudUploadOutline, trash } from 'ionicons/icons';
import React, { useCallback } from 'react';
import shallow from 'zustand/shallow';
import { css } from '@emotion/react';

import { Upload } from '../components';
import { MemberProgressChart, useMember } from '../features/Member';
import { useCalendar } from '../hooks/useCalendar';
import { MEMBER_PROFILE_PROGRESS } from '../hooks/useMemberProfileProgress';
import { useMemberProfileRatings } from '../hooks/useMemberProfileRatings';
import useS3Upload from '../hooks/useS3Upload';
import { useUpdateMemberRatings } from '../hooks/useUpdateMemberRatings';

import { useModal } from '.';

const { Toast } = Plugins;

type FormValues = Omit<MemberProfileRatings, 'score'>;

const RATING_DICT: Record<string, string> = {
    passing: 'Passing',
    shooting: 'Shooting',
    dribbling: 'Dribbling',
    gameAwareness: 'Game Awareness',
    score: 'Overall',
};

const MemberPerformanceModal: React.FC = () => {
    const member = useMember();
    const { showMemberPerformance, toggle } = useModal();
    const { date, change } = useCalendar();

    const { data, loading } = useMemberProfileRatings(member._id, date.year(), date.month());

    const [updateMemberRatings] = useUpdateMemberRatings();

    const { values, initialValues, handleChange, handleBlur, resetForm, submitForm, setFieldValue } =
        useFormik<FormValues>({
            enableReinitialize: true,
            initialValues: {
                year: date.year(),
                month: date.month(),
                passing: data?.member.ratings?.passing || 0,
                shooting: data?.member.ratings?.shooting || 0,
                dribbling: data?.member.ratings?.dribbling || 0,
                gameAwareness: data?.member.ratings?.gameAwareness || 0,
                report: data?.member.ratings?.report,
                feedback: data?.member.ratings?.feedback,
                competitionComment: data?.member.ratings?.competitionComment,
            },
            async onSubmit(input) {
                await updateMemberRatings({
                    refetchQueries: [
                        {
                            query: MEMBER_PROFILE_PROGRESS,
                            variables: {
                                id: member._id,
                            },
                        },
                    ],
                    variables: {
                        memberId: member._id,
                        input,
                        year: input.year,
                        month: input.month,
                    },
                });

                Toast.show({ text: 'Successfully Updated.' });
            },
        });

    const handleUpload = useS3Upload({
        onCompleted(key) {
            setFieldValue('report', key);
            Toast.show({ text: 'File uploaded.' });
        },
    });

    const Rating: React.FC<{
        name: keyof FormValues;
        value: number;
    }> = useCallback(
        ({ name, value }) => {
            return (
                <IonItem>
                    <IonLabel
                        position="fixed"
                        css={css`
                            white-space: normal !important;
                        `}
                    >
                        {RATING_DICT[name]}
                    </IonLabel>
                    {loading ? (
                        <IonSpinner slot="end" />
                    ) : (
                        <IonInput
                            {...{ name, value }}
                            type="number"
                            min="0"
                            max="100"
                            onIonChange={handleChange}
                            onIonBlur={handleBlur}
                        />
                    )}
                </IonItem>
            );
        },
        [handleBlur, handleChange, loading],
    );

    return (
        <Container
            backdropDismiss
            swipeToClose
            isOpen={showMemberPerformance}
            onDidDismiss={() => toggle('showMemberPerformance')}
        >
            <ContentContainer>
                {/* <IonLoading isOpen={loading} message="Please wait" /> */}

                <IonList lines="none">
                    <IonListHeader></IonListHeader>
                    <IonItem>
                        <IonLabel>Select Month</IonLabel>
                        <IonDatetime
                            displayFormat="MMM YYYY"
                            min="2021"
                            value={date.toISOString()}
                            onIonChange={(e) => e.detail.value && change(dayjs(e.detail.value))}
                        />

                        <IonIcon icon={chevronDown} />
                    </IonItem>

                    <IonItemDivider />

                    <Rating name="passing" value={values['passing']} />
                    <Rating name="shooting" value={values['shooting']} />
                    <Rating name="dribbling" value={values['dribbling']} />
                    <Rating name="gameAwareness" value={values['gameAwareness']} />

                    <MemberProgressChart />

                    <IonItemDivider />

                    <IonItem>
                        <IonLabel position="fixed">Feedback</IonLabel>
                        <IonTextarea
                            rows={5}
                            name="feedback"
                            value={values['feedback']}
                            placeholder="Type here"
                            onIonChange={handleChange}
                            onIonBlur={handleBlur}
                        />
                    </IonItem>

                    <IonItemDivider />

                    <IonItem>
                        <IonLabel
                            position="fixed"
                            css={css`
                                white-space: normal !important;
                            `}
                        >
                            Competition Comment
                        </IonLabel>
                        <IonTextarea
                            rows={5}
                            name="competitionComment"
                            value={values['competitionComment']}
                            placeholder="Type here"
                            onIonChange={handleChange}
                            onIonBlur={handleBlur}
                        />
                    </IonItem>

                    <IonItemDivider />

                    <IonItemSliding>
                        <IonItemOptions side="start">
                            <IonItemOption color="tertiary">
                                <IonIcon slot="icon-only" icon={cloudUploadOutline} />
                            </IonItemOption>
                            <Upload type="file" accept="application/pdf" onChange={handleUpload} />
                        </IonItemOptions>

                        <IonItem>
                            <IonLabel position="fixed">Report</IonLabel>
                            <IonNote slot="">{values.report || `Slide to upload`}</IonNote>
                        </IonItem>

                        <IonItemOptions side="end">
                            <IonItemOption
                                disabled={!values['report']}
                                color="secondary"
                                onClick={() =>
                                    Storage.get(values.report!).then((signedURL) => {
                                        window.open(signedURL as string);
                                    })
                                }
                            >
                                <IonIcon slot="icon-only" icon={cloudDownloadOutline} />
                            </IonItemOption>

                            <IonItemOption
                                disabled={!values['report']}
                                color="danger"
                                onClick={() => {
                                    Storage.remove(values.report!).then(() => {
                                        setFieldValue('report', null);

                                        Toast.show({ text: 'File deleted.' });
                                    });
                                }}
                            >
                                <IonIcon slot="icon-only" icon={trash} />
                            </IonItemOption>
                        </IonItemOptions>
                    </IonItemSliding>
                </IonList>
            </ContentContainer>

            {!shallow(initialValues, values) && (
                <FooterContainer>
                    <IonButton fill="outline" onClick={() => resetForm()}>
                        Cancel
                    </IonButton>

                    <IonButton onClick={() => submitForm()}>Save</IonButton>
                </FooterContainer>
            )}
        </Container>
    );
};

export default MemberPerformanceModal;

const Container = styled(IonModal)`
    .modal-wrapper[role='dialog'] {
        height: 95vh;
        margin-top: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
`;

const ContentContainer = styled(IonContent)`
    height: 100%;
    padding: 1rem;

    ion-button:first-of-type {
        margin-top: auto;
    }

    ion-input {
        text-align: right;
    }
`;

const FooterContainer = styled(IonFooter)`
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`;
