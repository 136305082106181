import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const CREATE_PROGRAM = gql`
    mutation CreateProgram($input: CreateProgramInput!) {
        createProgram(input: $input) {
            _id
            name
            type
            link
            startDate
            endDate
            startTime
            endTime
            address
            fee
        }
    }
`;

type TData = { createProgram: Program };
type TVariables = { input: Omit<Program, '_id'> };

export const useCreateProgram = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(CREATE_PROGRAM, options);
};
