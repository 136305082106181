import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const UPDATE_SIDEBAR = gql`
    mutation UpdateSidebar($id: String!, $input: UpdateSidebarInput!) {
        updateSidebar(id: $id, input: $input) {
            _id
            title
            link
        }
    }
`;

type TData = { updateSidebar: Sidebar };
type TVariables = { id: string; input: { key: string; value: string } };

export const useUpdateSidebar = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(UPDATE_SIDEBAR, options);
};
