import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export interface UpdateMemberAccountInput extends Partial<MemberAccountDetails> {
    _id: string;
}

const UPDATE_MEMBER_ACCOUNT_MUTATION = gql`
    mutation updateMember($input: UpdateMemberAccountInput!) {
        updateMember(input: $input) {
            _id
        }
    }
`;

export const useUpdateMemberAccountDetails = (
    options?: MutationHookOptions<{ updateMember: MemberDetails }, { input: UpdateMemberAccountInput }>,
) => {
    return useMutation<{ updateMember: MemberDetails }, { input: UpdateMemberAccountInput }>(
        UPDATE_MEMBER_ACCOUNT_MUTATION,
        options,
    );
};
