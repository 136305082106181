import { gql, MutationHookOptions, useMutation } from '@apollo/client';

interface UpdateVideoApprovedInput {
    approve: boolean;
}

export const UPDATE_VIDEO_APPROVED_REQUEST = gql`
    mutation UpdateVideoApproved($id: String!, $input: UpdateVideoApprovedInput!) {
        updateVideoApproved(id: $id, input: $input) {
            _id
        }
    }
`;

type TData = { updateVideoApproved: { _id: string } };
type TVariables = { id: string; input: UpdateVideoApprovedInput };

export const useUpdateVideoApproveRequest = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, { id: string; input: UpdateVideoApprovedInput }>(UPDATE_VIDEO_APPROVED_REQUEST, options);
};
