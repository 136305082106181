import styled from '@emotion/styled';
import { IonCard } from '@ionic/react';
import { Storage } from 'aws-amplify';
import dayjs from 'dayjs';
import React from 'react';

const ApprovalCardMini: React.FC<{ data: PaymentApproval }> = ({ data }) => {
    return (
        <Container
            onClick={() => {
                Storage.get(data.attachment).then((signedURL) => {
                    window.open(signedURL as string);
                });
            }}
        >
            <Meta>{dayjs(data.updatedAt).fromNow()}</Meta>
            <Meta>{data.member.name}</Meta>
            <Meta>${data.amount}</Meta>
        </Container>
    );
};

export default ApprovalCardMini;

const Container = styled(IonCard)`
    margin: 0;
    margin-left: 1rem;
    padding: 1rem;
`;

const Meta = styled.div`
    font-size: smaller;
`;
