import { gql, useQuery } from '@apollo/client';

export const useMemberProfile = (id: string) => {
    return useQuery<{
        member: MemberProfile;
    }>(MEMBER_PROFILE, { variables: { id } });
};

export const MEMBER_PROFILE = gql`
    query Member($id: String!) {
        member(id: $id) {
            _id
            name
            picture
            age
            gender
            birthdate
            jerseySize
            experience
            tags
            createdAt
            lastActiveAt
            account {
                _id
                name
                email
                phoneNumber
                numTokens
            }
            relatedProfiles {
                _id
                name
            }
            loyaltyPoint
        }
    }
`;
