/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { IonIcon, IonItem, IonItemGroup } from '@ionic/react';
import { chevronForwardOutline, chevronUpOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { useTrainingVideos } from '../../hooks/useTrainingVideos';

import CatalogueBlock from './CatalogueBlock';

interface CatalogueDropdownProps {
    catalogue: Pick<Catalogue, '_id' | 'name'>;
}

const CatalogueDropdown: React.FunctionComponent<CatalogueDropdownProps> = ({ catalogue }) => {
    const [isDropped, setIsDropped] = useState<boolean>(false);
    const history = useHistory<{ memberId: string }>();
    const memberId = useRef(history.location.state?.memberId);

    const [getVideos, { data }] = useTrainingVideos({
        variables: {
            isApproved: false,
            catalogueId: catalogue._id,
            memberId: memberId.current,
        },
    });

    useEffect(() => {
        if (isDropped && !data?.trainingVideos?.length) {
            getVideos();
        }
    }, [isDropped]);

    return (
        <>
            <IonItemGroup>
                <HeaderItem className="ion-activatable ripple-parent" onClick={() => setIsDropped(!isDropped)}>
                    <div>{catalogue.name}</div>
                    <IonIcon size="small" color="sliver" icon={isDropped ? chevronUpOutline : chevronForwardOutline} />
                </HeaderItem>

                {isDropped && (
                    <ContentContainer>
                        {data?.trainingVideos?.length ? (
                            <>
                                {data.trainingVideos.map((video, index) => (
                                    <CatalogueBlock
                                        key={index}
                                        title={`Member ${video?.memberId?.name} - ${video.videoUrl.split('-')[2]}`}
                                        video={video}
                                        catalogueId={catalogue._id}
                                        memberId={memberId.current}
                                    />
                                ))}
                            </>
                        ) : (
                            <p>No video</p>
                        )}
                    </ContentContainer>
                )}
            </IonItemGroup>
        </>
    );
};

export default CatalogueDropdown;

const HeaderItem = styled(IonItem)`
    div {
        width: 100%;
    }
    ion-icon {
        float: right;
    }
`;

const ContentContainer = styled.div`
    p {
        margin-left: 30px;
    }
`;
