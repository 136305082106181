import { CognitoUser } from '@aws-amplify/auth';
import styled from '@emotion/styled';
import { IonButton, IonContent, IonInput, IonItem, IonLoading, IonPage, IonText } from '@ionic/react';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import tw from 'twin.macro';

enum Challenge {
    NONE,
    RESET_PASSWORD,
    NEW_PASSWORD,
}

const Login = () => {
    const [user, setUser] = useState<CognitoUser>();
    const {
        isSubmitting,
        handleChange,
        handleSubmit,
        setFieldValue,
        setSubmitting,
        values: { username, password, newPassword, challenge, code, message },
    } = useFormik({
        initialValues: {
            username: '',
            password: '',
            newPassword: '',
            challenge: Challenge.NONE,
            code: '',
            message: '',
        },
        async onSubmit({ username, password, newPassword, challenge }) {
            try {
                if (challenge) {
                    switch (challenge) {
                        case Challenge.NEW_PASSWORD:
                            await Auth.completeNewPassword(
                                user, // the Cognito User Object
                                newPassword, // the new password
                                {}, // no required attributes
                            );
                            break;
                        case Challenge.RESET_PASSWORD:
                            const res = await Auth.forgotPasswordSubmit(username, code, newPassword);
                            console.log(res);
                            break;
                        default:
                            console.log('Challenge not implemented');
                            break;
                    }
                    window.location.href = '/';
                } else {
                    const user = await Auth.signIn(username, password).catch(console.log);
                    setUser(user); // store in local state for different scenarios

                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        setFieldValue('challenge', Challenge.NEW_PASSWORD);
                        setSubmitting(false);
                    } else {
                        // The user directly signs in
                        window.location.href = '/';
                    }
                }
            } catch (err) {
                setFieldValue('message', err.message);
                if (err.code === 'UserNotConfirmedException') {
                    // The error happens if the user didn't finish the confirmation step
                    // when signing up In this case you need to resend the code and
                    // confirm the user About how to resend the code and confirm the
                    // user, please check the signUp part
                } else if (err.code === 'PasswordResetRequiredException') {
                    // The error happens when the password is reset in the Cognito console
                    // In this case you need to call forgotPassword to reset the password
                    // Please check the Forgot Password part.
                    setFieldValue('challenge', Challenge.RESET_PASSWORD);
                    setSubmitting(false);
                } else if (err.code === 'NotAuthorizedException') {
                    // The error happens when the incorrect password is provided
                } else if (err.code === 'UserNotFoundException') {
                    // The error happens when the supplied username/email does not exist
                    // in the Cognito user pool
                } else {
                    console.log(err.code);
                }
            }
        },
    });

    return (
        <PageContainer>
            <IonContent>
                <Container>
                    <IonLoading isOpen={isSubmitting} />
                    <IonText>
                        <h1>Staff Login</h1>
                    </IonText>
                    <FormContainer onSubmit={handleSubmit}>
                        {message && (
                            <IonItem className="message" lines="none">
                                <IonText>{message}</IonText>
                            </IonItem>
                        )}
                        {challenge ? (
                            <>
                                {challenge === Challenge.RESET_PASSWORD && (
                                    <IonItem>
                                        <IonInput
                                            type="text"
                                            name="code"
                                            placeholder="Enter verification code"
                                            value={code}
                                            onIonChange={(e) => setFieldValue('code', e.detail.value)}
                                            onIonInput={handleChange as any}
                                        />
                                        <IonButton slot="end" size="small" color="light">
                                            RESEND
                                        </IonButton>
                                    </IonItem>
                                )}
                                <IonItem>
                                    <IonInput
                                        type="password"
                                        name="newPassword"
                                        placeholder="Enter a new password"
                                        value={newPassword}
                                        onIonChange={(e) => setFieldValue('newPassword', e.detail.value)}
                                        onIonInput={handleChange as any}
                                    />
                                </IonItem>
                                <IonButton color="light" fill="solid" type="submit">
                                    SUBMIT
                                </IonButton>
                            </>
                        ) : (
                            <>
                                <IonItem>
                                    <IonInput
                                        name="username"
                                        value={username}
                                        type="email"
                                        placeholder="Email"
                                        inputMode="email"
                                        onIonChange={(e) => setFieldValue('username', e.detail.value)}
                                        onIonInput={handleChange as any}
                                    />
                                </IonItem>
                                <IonItem>
                                    <IonInput
                                        name="password"
                                        value={password}
                                        placeholder="Password"
                                        type="password"
                                        onIonChange={(e) => setFieldValue('password', e.detail.value)}
                                        onIonInput={handleChange as any}
                                    />
                                </IonItem>
                                <IonButton color="light" fill="solid" type="submit">
                                    LOG IN
                                </IonButton>
                            </>
                        )}
                    </FormContainer>
                </Container>
            </IonContent>
        </PageContainer>
    );
};

export default Login;

const PageContainer = styled(IonPage)`
    ion-content {
        --background: var(--ion-color-primary);
        --color: var(--ion-color-primary-contrast);
    }
`;

const Container = styled.div`
    ${tw`h-full py-32 px-12 max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl mx-auto`}
`;

const FormContainer = styled.form`
    ${tw`flex flex-col items-stretch justify-center`}

    ion-item {
        ${tw`mb-4`}

        font-size: smaller;

        &:not(.message) {
            --background: var(--ion-color-primary);
            --border-color: var(--ion-color-primary-contrast);
            --color: var(--ion-color-primary-contrast);

            &:first-of-type {
                ${tw`mt-8`}
            }

            &:last-of-type {
                ${tw`mb-16`}
            }
        }
        &.message {
            --background: var(--ion-color-primary-contrast);
            --color: var(--ion-color-primary);
            --border-radius: 0.5rem;
        }
    }

    ion-button {
        font-size: smaller;
        &.ion-color-light {
            --ion-color-contrast: var(--ion-color-primary) !important;
            --ion-color-contrast-rgb: var(--ion-color-primary-rgb) !important;
        }
    }

    & > ion-button {
        ${tw`w-3/4 mx-auto`}
    }
`;
