import styled from '@emotion/styled';
import { IonAvatar, IonButton, IonList, IonListHeader, IonLoading, IonModal } from '@ionic/react';
import dayjs from 'dayjs';
import { Formik, useField } from 'formik';
import React, { useMemo } from 'react';

import { Field, FieldColumnProps, Flex } from '../components';
import { useMember } from '../features/Member';
import { useAvatar } from '../hooks/useAvatar';
import { UpdateMemberInput, useUpdateMemberDetails } from '../hooks/useUpdateMemberDetails';

import { useModal } from '.';

const Avatar: React.FC = () => {
    const [{ value }] = useField('picture');
    const { src, alt } = useAvatar(value);
    return (
        <AvatarContainer>
            <img {...{ src, alt }} />
        </AvatarContainer>
    );
};

const AvatarContainer = styled(IonAvatar)`
    width: 128px;
    height: 128px;
    margin: 1rem auto;
`;

const EditMemberDetailsModal = () => {
    const profile = useMember();
    const { showEditMemberDetails, toggle } = useModal();
    const [updateMemberDetails] = useUpdateMemberDetails({
        awaitRefetchQueries: true,
        refetchQueries: ['Member'],
        onCompleted() {
            toggle('showEditMemberDetails', false);
        },
    });

    const fields = useMemo(() => {
        const MEMBER_FIELDS: FieldColumnProps<keyof UpdateMemberInput>[] = [
            {
                name: 'picture',
                variant: 'uploadInput',
                label: 'Picture',
                children: <Avatar />,
                uuid: profile._id,
                accept: 'image/*',
            },
            { name: 'name', variant: 'textInput', label: 'Name' },
            {
                name: 'birthdate',
                variant: 'birthdatePicker',
                label: 'Date of Birth',
                children: dayjs(profile.birthdate).format('DD/MM/YYYY'),
            },
            {
                name: 'gender',
                variant: 'genderPicker',
                label: 'Gender',
            },
            {
                name: 'experience',
                variant: 'experiencePicker',
                label: 'Experience',
            },
            {
                name: 'jerseySize',
                variant: 'jerseySizePicker',
                label: 'Jersey Size',
            },
        ];

        return MEMBER_FIELDS.map(({ name, ...props }) => <Field key={name} {...{ name }} {...props} />);
    }, [profile._id, profile.birthdate]);

    return (
        <Container isOpen={showEditMemberDetails} onDidDismiss={() => toggle('showEditMemberDetails', false)}>
            <Formik<MemberDetails>
                initialValues={profile}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await updateMemberDetails({
                            variables: {
                                input: {
                                    memberId: values._id,
                                    birthdate: values.birthdate,
                                    gender: values.gender,
                                    name: values.name,
                                    jerseySize: values.jerseySize,
                                    picture: values.picture,
                                    experience: values.experience,
                                },
                            },
                        });
                    } catch (err) {
                        console.log(err);
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <ContentContainer>
                        <IonLoading isOpen={isSubmitting} message="Please wait" />

                        <IonList>
                            <IonListHeader>Edit Member Details</IonListHeader>

                            {fields}
                        </IonList>

                        <IonButton onClick={() => handleSubmit()}>Save</IonButton>

                        <IonButton fill="outline" onClick={() => toggle('showEditMemberDetails', false)}>
                            Cancel
                        </IonButton>
                    </ContentContainer>
                )}
            </Formik>
        </Container>
    );
};

export default EditMemberDetailsModal;

const Container = styled(IonModal)`
    color: var(--ion-text-color);

    .modal-wrapper[role='dialog'] {
        height: 95vh;
        margin-top: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
`;

const ContentContainer = styled(Flex)`
    height: 100%;
    padding: 1rem;

    ion-button:first-of-type {
        margin-top: auto;
    }
`;
