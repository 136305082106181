import { gql, useQuery } from '@apollo/client';

export const usePendingPaymentApprovals = () => {
    return useQuery<{ pendingPaymentApprovals: PaymentApproval[] }>(PENDING_PAYMENT_APPROVALS);
};

const PENDING_PAYMENT_APPROVALS = gql`
    query PendingPaymentApprovals {
        pendingPaymentApprovals {
            _id
            orderId
            memberId
            member {
                name
            }
            programs {
                programId
                quantity
            }
            amount
            currency
            notes
            attachment
            status
            createdAt
            updatedAt
        }
    }
`;
