import styled from '@emotion/styled';
import { IonAlert, IonButton } from '@ionic/react';
import React, { useState } from 'react';

import { GET_LIST_COMPETITIONS } from '../hooks/useCompetitions';
import { CompetitionFields, useCreateCompetition } from '../hooks/useCreateCompetition';

const CreateCompetitionModal = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [createCompetition] = useCreateCompetition({
        update: (cache, { data }) => {
            const existedList = cache.readQuery<{ competitions: Competition[] }>({
                query: GET_LIST_COMPETITIONS,
            });
            if (data?.createCompetition && existedList) {
                cache.writeQuery<{ competitions: Competition[] }>({
                    query: GET_LIST_COMPETITIONS,
                    data: {
                        competitions: [...existedList.competitions, data.createCompetition],
                    },
                });
            }
        },
    });

    const handleOnCreateNewCompetition = (value: CompetitionFields) => {
        if (!value.name) return;
        createCompetition({ variables: { input: { name: value.name } } });
        setShowModal(false);
    };

    return (
        <Container>
            <section>
                <IonButton expand="block" onClick={() => setShowModal(!showModal)}>
                    Add a new competition
                </IonButton>
            </section>
            <IonAlert
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
                header="Create a new competition"
                inputs={[{ name: 'name', placeholder: 'Competition Name', type: 'text' }]}
                buttons={[
                    { text: 'Cancel', role: 'cancel' },
                    { text: 'Save', handler: handleOnCreateNewCompetition },
                ]}
            />
        </Container>
    );
};

export default CreateCompetitionModal;

const Container = styled.div`
    section {
        margin: 1rem 3rem;
    }

    div {
        background-color: white;
        width: 70vw;
    }
`;
