import { IonAvatar, IonItem, IonLabel, IonSkeletonText } from '@ionic/react';
import React from 'react';

import { MemberAvatar } from './';

interface Props extends React.FC<MemberProfile> {
    Placeholder: typeof Placeholder;
}

const MembersItem: Props = (profile) => {
    const { _id: id, name, tags } = profile;
    return (
        <IonItem detail routerLink={`/members/${id}`}>
            <MemberAvatar slot="start" item {...{ profile }} />
            <IonLabel>
                <h2>{name}</h2>
                <p>
                    {tags.map((tag) => (
                        <span key={tag}>{tag}</span>
                    ))}
                </p>
            </IonLabel>
        </IonItem>
    );
};

const Placeholder = () => {
    return (
        <IonItem>
            <IonAvatar slot="start">
                <IonSkeletonText animated />
            </IonAvatar>
            <IonLabel>
                <h2>
                    <IonSkeletonText animated />
                </h2>
                <p>
                    <IonSkeletonText animated />
                </p>
            </IonLabel>
        </IonItem>
    );
};

MembersItem.Placeholder = Placeholder;

export default MembersItem;
