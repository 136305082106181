import { gql, MutationHookOptions, useMutation } from '@apollo/client';

type TData = { checkInMember: unknown };
type TVariables = { memberId: string; date: string; programId: string };

export const useCheckInMutation = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(
        gql`
            mutation CheckInMember($memberId: String!, $date: String!, $programId: String!) {
                checkInMember(memberId: $memberId, date: $date, programId: $programId) {
                    _id
                }
            }
        `,
        options,
    );
};
