/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonButton, IonChip, IonIcon, IonLabel, IonText } from '@ionic/react';
import dayjs from 'dayjs';
import { checkmarkOutline } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';

import { MemberAvatar, useMember } from '.';

const Membership: React.FC = () => {
    const profile = useMember();
    const history = useHistory();
    const { createdAt, lastActiveAt, name, tags, loyaltyPoint } = profile;
    const hasAttendedToday = dayjs().isSame(dayjs(lastActiveAt), 'day');

    const handleClickBtn = (url: string) => {
        history.push({
            pathname: url,
            state: {
                memberId: profile._id,
            },
        });
    };

    return (
        <Container>
            <MemberAvatar item {...{ profile }} />

            <div
                css={css`
                    display: grid;
                    grid-gap: 0.5rem;
                `}
            >
                <LargeText css={css``}>{name}</LargeText>

                <SmallText>{[...tags, `JOINED IN ${dayjs(createdAt).year()}`].join(' | ')}</SmallText>

                <IonText color="tertiary">Loyalty Points: {loyaltyPoint}</IonText>

                {hasAttendedToday && (
                    <IonChip color="success">
                        <IonIcon icon={checkmarkOutline} />
                        <IonLabel>Checked-in</IonLabel>
                    </IonChip>
                )}
            </div>
            <div>
                <IonButton
                    css={css`
                        font-size: 12px;
                        padding: 0px 0;
                        margin: 8px 0;
                        --background: var(--ion-blue-button-color);
                    `}
                    onClick={() => handleClickBtn('/training-videos')}
                    expand="block"
                >
                    Watch
                </IonButton>
                <IonButton
                    css={css`
                        font-size: 12px;
                        padding: 0px 0;
                        margin: 8px 0;
                        --background: var(--ion-header-background);
                    `}
                    expand="block"
                    onClick={() => handleClickBtn('/assign-member')}
                >
                    Training
                </IonButton>
            </div>
        </Container>
    );
};

export default Membership;

const Container = styled.div`
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ion-avatar {
        width: 64px;
        height: 64px;
    }
`;

const LargeText = styled(IonText)`
    font-size: larger;
    font-weight: 500;
`;

const SmallText = styled(IonText)`
    font-size: small;
`;
