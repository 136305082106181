import { IonLoading, IonPage } from '@ionic/react';
import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';

const Logout = () => {
    useEffect(() => {
        Auth.signOut().then(() => {
            window.location.href = '/login';
        });
    }, []);

    return (
        <IonPage>
            <IonLoading isOpen />
        </IonPage>
    );
};

export default Logout;
