import { IonProgressBar } from '@ionic/react';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { useMemberProfile } from '../../hooks/useMemberProfile';

const MemberGuard: React.FC = ({ children }) => {
    const { id } = useParams<{ id: string }>();
    const { data, loading } = useMemberProfile(id);

    if (loading) {
        return <IonProgressBar type="indeterminate" />;
    }

    if (!id || !data) {
        return <Redirect to="/members" />;
    }

    return <>{children}</>;
};

export default MemberGuard;
