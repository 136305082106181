/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';

import { Flex } from '../components';
import { ApprovalCard, ApprovalCardMini } from '../features/Approval';
import { usePendingPaymentApprovals } from '../hooks/usePendingPaymentApprovals';
import { useRecentPaymentApprovals } from '../hooks/useRecentPaymentApprovals';

const PendingApprovalList = () => {
    const { data, loading } = usePendingPaymentApprovals();
    const [isSendingRequest, setIsSendingRequest] = useState(false);

    if (loading) return null;

    if (!data) return null;

    if (!data.pendingPaymentApprovals.length)
        return (
            <IonItem>
                <IonLabel>All payments resolved.</IonLabel>
            </IonItem>
        );

    return (
        <>
            <IonLoading isOpen={isSendingRequest} />
            <IonList lines="none">
                <IonList>
                    <IonListHeader>Pending</IonListHeader>
                    {data.pendingPaymentApprovals
                        .filter((approval) => approval.status === 'PENDING')
                        .map((approval) => (
                            <ApprovalCard
                                key={approval._id}
                                data={approval}
                                setIsSendingRequest={setIsSendingRequest}
                            />
                        ))}
                </IonList>
            </IonList>
            <IonList lines="none">
                <IonList>
                    <IonListHeader>Requesting</IonListHeader>
                    {data.pendingPaymentApprovals
                        .filter((approval) => approval.status === 'REQUESTING')
                        .map((approval) => (
                            <ApprovalCard
                                key={approval._id}
                                data={approval}
                                setIsSendingRequest={setIsSendingRequest}
                            />
                        ))}
                </IonList>
            </IonList>
        </>
    );
};

const RecentApprovalList = () => {
    const { data, loading } = useRecentPaymentApprovals();

    if (loading) return null;

    if (!data) return null;

    return (
        <RecentlyApproved>
            <h5>Recent</h5>
            {data.recentPaymentApprovals.map((approval) => (
                <ApprovalCardMini key={approval._id} data={approval} />
            ))}
        </RecentlyApproved>
    );
};

const Approvals: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Approvals</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent
                css={css`
                    --padding-bottom: 8rem;
                `}
            >
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Approvals</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <Container>
                    <ContentContainer>
                        <PendingApprovalList />
                    </ContentContainer>
                </Container>

                {/* FIXME: */}
                <div
                    css={css`
                        bottom: 0;
                        left: 0;
                        right: 0;
                    `}
                    slot="fixed"
                >
                    <RecentApprovalList />
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Approvals;

const Container = styled(Flex)`
    height: 100%;
`;

const ContentContainer = styled.div`
    flex: 1;
    min-width: 0;
    overflow-y: auto;
`;

const RecentlyApproved = styled(Flex)`
    background: #ffffff;
    box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: auto;

    align-items: center;
    padding: 1rem;

    & > * {
        flex-shrink: 0;
    }
`;
