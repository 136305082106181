import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const GET_SIDEBAR = gql`
    query GetSidebars {
        sidebars {
            _id
            title
            link
        }
    }
`;

type TData = { sidebars: Sidebar[] };

export const useSidebar = (options?: QueryHookOptions<TData>) => {
    return useQuery<TData>(GET_SIDEBAR, options);
};
