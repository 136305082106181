import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';

interface TData {
    members: MemberProfile[];
}

export const useMembers = (options: LazyQueryHookOptions<TData>) => {
    return useLazyQuery<TData>(
        gql`
            query Members($searchTerm: String, $cursor: String, $filter: MembersFilterInput) {
                members(searchTerm: $searchTerm, cursor: $cursor, filter: $filter) {
                    _id
                    name
                    picture
                    birthdate
                    tags
                }
            }
        `,
        options,
    );
};
