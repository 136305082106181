/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import './theme/global.css';
/* Plugins */
import './plugins/amplify';
import './plugins/dayjs';

import { ApolloProvider } from '@apollo/client';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthProvider, PrivateRoute } from './features/Auth';
import { MemberCheckInModal } from './modals';
import Approvals from './pages/Approvals';
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Member from './pages/Member';
import Members from './pages/Members';
import Settings from './pages/Settings';
import AssignMember from './pages/AssignMember';
import { client } from './services/apollo';
import OnlineCompetitions from './pages/OnlineCompetitions';
import Ticket from './pages/Ticket';
import TrainingVideos from './pages/TrainingVideos';
import ManagePrograms from './pages/ManagePrograms';
import Banners from './pages/Banners';
import TrainingCatalogues from './pages/TrainingCatalogues';

const App: React.FC = () => {
    return (
        <AuthProvider>
            <ApolloProvider client={client}>
                <IonApp>
                    <IonReactRouter>
                        <MemberCheckInModal />
                        <IonRouterOutlet>
                            <Route path="/login" component={Login} exact={true} />
                            <Route path="/logout" component={Logout} exact={true} />
                            <PrivateRoute path="/members" component={Members} exact={true} />
                            <PrivateRoute path="/members/:id" component={Member} />
                            <PrivateRoute path="/settings" component={Settings} exact={true} />
                            <PrivateRoute path="/online-competitions" component={OnlineCompetitions} exact={true} />
                            <PrivateRoute path="/training-catalogues" component={TrainingCatalogues} exact={true} />
                            {/* <PrivateRoute path="/ticket" component={Ticket} exact={true} /> */}
                            <PrivateRoute path="/approvals" component={Approvals} exact={true} />
                            <PrivateRoute path="/training-videos" component={TrainingVideos} exact={true} />
                            <PrivateRoute path="/assign-member" component={AssignMember} exact={true} />
                            <PrivateRoute path="/banners" component={Banners} exact={true} />
                            <PrivateRoute path="/manage-programs" component={ManagePrograms} exact={true} />
                            <PrivateRoute path="/" component={Home} exact={true} />
                            <Redirect to="/" />
                        </IonRouterOutlet>
                    </IonReactRouter>
                </IonApp>
            </ApolloProvider>
        </AuthProvider>
    );
};

export default App;
