import styled from '@emotion/styled';
import { IonToast, IonAlert, IonButton, AlertInput } from '@ionic/react';
import dayjs from 'dayjs';
import React, { useMemo, useRef, useState } from 'react';

import { useCreateProgram } from '../hooks/useCreateProgram';
import { ToastProps } from '../pages/ManagePrograms';

type ProgramField = Omit<Program, '_id'>;

interface CreateProgramModalProps {
    onCreatedProgram: (newProgram: Program) => void;
    setToast: React.Dispatch<React.SetStateAction<ToastProps>>;
}

const CreateProgramModal: React.FunctionComponent<CreateProgramModalProps> = ({ onCreatedProgram, setToast }) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalSelectType, setShowModalSelectType] = useState(false);
    const [programType, setProgramType] = useState('session');
    const currentDateFormat = useRef(dayjs().format('YYYY-MM-DD'));

    const [createProgram] = useCreateProgram({
        onCompleted: (data) => {
            onCreatedProgram(data.createProgram);
        },
        onError: () => {
            setToast({ isOpen: true, message: 'Something wrong. Please try again', color: 'primary' });
        },
    });

    const handleOnCreateNewProgram = (input: any) => {
        const { address, endTime, link, name, startTime, endDate, startDate } = input;
        const formattedInput: ProgramField = {
            address,
            endTime,
            fee: parseFloat(input.fee),
            link,
            name,
            startTime,
            endDate,
            startDate,
            type: programType,
        };
        if (!input.address || !input.name || !input.endTime || !input.fee || !input.link || !input.startTime) {
            setToast({ isOpen: true, message: 'Field can not be empty', color: 'primary' });
            return false;
        }
        if (parseFloat(input.fee) < 1) {
            setToast({ isOpen: true, message: 'Program price must be greater than 1', color: 'primary' });
            return false;
        }
        const startMoment = dayjs(`${startDate || currentDateFormat.current} ${startTime}`);
        const endMoment = dayjs(`${endDate || currentDateFormat.current} ${endTime}`);
        if (endMoment.isSameOrBefore(startMoment)) {
            setToast({ isOpen: true, message: 'Date is invalid', color: 'primary' });
            return false;
        }
        createProgram({ variables: { input: formattedInput } });
    };

    const handleOnValidate = (e: FocusEvent) => {
        const value = (e.target as any).value as string;
        if (!value.length) {
            setToast({ isOpen: true, message: 'Field can not be empty', color: 'primary' });
        }
    };

    const handleOnValidateFee = (e: FocusEvent) => {
        const value = (e.target as any).value as string;
        if (parseFloat(value) < 1) {
            setToast({ isOpen: true, message: 'Program price must be greater than 1', color: 'primary' });
        }
    };

    return (
        <Container>
            <section>
                <IonButton expand="block" onClick={() => setShowModalSelectType(true)}>
                    Add a new program
                </IonButton>
            </section>
            <IonAlert
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
                header="Program Detail"
                cssClass="program-alert"
                inputs={[
                    {
                        name: 'name',
                        placeholder: 'Program Name*',
                        type: 'text',
                        attributes: {
                            onBlur: handleOnValidate,
                        },
                    },
                    {
                        name: 'link',
                        placeholder: 'Reference Link*',
                        type: 'url',
                        attributes: {
                            onBlur: handleOnValidate,
                        },
                    },
                    { value: 'Start Date', cssClass: ['date-input', 'read-only-input'], disabled: true },
                    {
                        value: 'End Date',
                        cssClass: ['date-input', 'end-date-input', 'read-only-input'],
                        disabled: true,
                    },
                    {
                        name: 'startDate',
                        type: 'date',
                        cssClass: 'date-input',
                        min: currentDateFormat.current,
                        max: dayjs().add(2, 'year').format('YYYY-MM-DD'),
                    },
                    {
                        name: 'endDate',
                        type: 'date',
                        min: currentDateFormat.current,
                        cssClass: ['date-input', 'end-date-input'],
                        max: dayjs().add(2, 'year').format('YYYY-MM-DD'),
                    },
                    { value: 'Start Time*', cssClass: ['date-input', 'read-only-input'], disabled: true },
                    {
                        value: 'End Time*',
                        cssClass: ['date-input', 'end-date-input', 'read-only-input'],
                        disabled: true,
                    },
                    { name: 'startTime', type: 'time', cssClass: 'date-input' },
                    { name: 'endTime', type: 'time', cssClass: ['date-input', 'end-date-input'] },
                    {
                        name: 'address',
                        placeholder: 'Venue Address*',
                        type: 'text',
                        attributes: {
                            onBlur: handleOnValidate,
                        },
                    },
                    {
                        name: 'fee',
                        placeholder: 'Program Fees*',
                        type: 'number',
                        min: '1',
                        attributes: {
                            onBlur: handleOnValidateFee,
                        },
                    },
                ]}
                buttons={[
                    { text: 'Cancel', role: 'cancel' },
                    { text: 'Save', handler: handleOnCreateNewProgram },
                ]}
            />
            <IonAlert
                isOpen={showModalSelectType}
                onDidDismiss={() => setShowModalSelectType(false)}
                header="Choose type of Program"
                inputs={[
                    {
                        name: 'type',
                        label: 'Session',
                        value: 'session',
                        type: 'radio',
                        checked: true,
                        handler: (input) => setProgramType(input.value!),
                    },
                    {
                        name: 'type',
                        label: 'Monthly',
                        value: 'monthly',
                        type: 'radio',
                        handler: (input) => setProgramType(input.value!),
                    },
                ]}
                buttons={[
                    { text: 'Cancel', role: 'cancel' },
                    {
                        text: 'Save',
                        handler: () => {
                            setShowModal(true);
                        },
                    },
                ]}
            />
        </Container>
    );
};

export default CreateProgramModal;

const Container = styled.div`
    section {
        margin: 1rem 3rem;
    }

    div {
        background-color: white;
        width: 70vw;
    }
`;
