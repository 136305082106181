import styled from '@emotion/styled';
import { IonButton, IonList, IonListHeader, IonLoading, IonModal } from '@ionic/react';
import { Formik } from 'formik';
import React, { useMemo } from 'react';

import { Field, FieldColumnProps, Flex } from '../components';
import { useMember } from '../features/Member';
import { UpdateMemberAccountInput, useUpdateMemberAccountDetails } from '../hooks/useUpdateMemberAccountDetails';

import { useModal } from '.';

const EditAccountDetailsModal = () => {
    const profile = useMember();
    const { showEditMemberAccountDetails, toggle } = useModal();
    const [updateMemberAccountDetails] = useUpdateMemberAccountDetails();

    const fields = useMemo(() => {
        const ACCOUNT_FIELDS: FieldColumnProps<keyof UpdateMemberAccountInput | 'experience'>[] = [
            {
                name: 'name',
                label: 'Contact Name',
                variant: 'textInput',
            },
            {
                name: 'email',
                label: 'Email',
                variant: 'textInput',
            },
            {
                name: 'phoneNumber',
                label: 'Phone',
                variant: 'textInput',
            },
        ];

        return ACCOUNT_FIELDS.map(({ name, ...props }) => <Field key={name} {...{ name }} {...props} />);
    }, []);

    return (
        <Container
            isOpen={showEditMemberAccountDetails}
            onDidDismiss={() => toggle('showEditMemberAccountDetails', false)}
        >
            <Formik<MemberAccountDetails>
                initialValues={profile.account}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await updateMemberAccountDetails({
                            variables: {
                                input: {
                                    _id: values._id,
                                    name: values.name,
                                    email: values.email,
                                    phoneNumber: values.phoneNumber,
                                },
                            },
                        });

                        toggle('showEditMemberAccountDetails', false);
                    } catch (err) {
                        console.log(err);
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <ContentContainer>
                        <IonLoading isOpen={isSubmitting} message="Please wait" />

                        <IonList>
                            <IonListHeader>Edit Member Account Details</IonListHeader>

                            {fields}
                        </IonList>

                        <IonButton onClick={() => handleSubmit()}>Save</IonButton>
                        <IonButton fill="outline" onClick={() => toggle('showEditMemberAccountDetails', false)}>
                            Cancel
                        </IonButton>
                    </ContentContainer>
                )}
            </Formik>
        </Container>
    );
};

export default EditAccountDetailsModal;

const Container = styled(IonModal)`
    color: var(--ion-text-color);

    .modal-wrapper[role='dialog'] {
        height: 95vh;
        margin-top: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
`;

const ContentContainer = styled(Flex)`
    height: 100%;
    padding: 1rem;

    ion-button:first-of-type {
        margin-top: auto;
    }
`;
