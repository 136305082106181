import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const UPDATE_CATALOGUE_DETAIL = gql`
    mutation UpdateCatalogueDetail($id: String!, $input: UpdateCatalogueDetailInput!) {
        updateCatalogueDetail(id: $id, input: $input) {
            _id
            name
            videoDemo
            thumbnail
        }
    }
`;

type TData = { updateCatalogueDetail: Catalogue };
type TVariables = { id: string; input: { name: string; value: string } };

export const useUpdateCatalogueDetail = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(UPDATE_CATALOGUE_DETAIL, options);
};
