/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonLoading,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import AssignMemberSwitch from '../features/AssignMember/AssignMemberSwitch';
import { useCatalogues } from '../hooks/useCatalogues';

const AssignMember: React.FC = (): React.ReactElement => {
    const { data } = useCatalogues();
    const [catalogues, setCatalogues] = useState<Catalogue[]>([]);
    const history = useHistory<{ memberId: string }>();
    const memberId = history.location.state?.memberId;
    const [loading, setIsLoading] = useState(false);

    useEffect(() => {
        if (data?.catalogues) {
            setCatalogues(data.catalogues);
        }
    }, [data]);

    return (
        <IonPage>
            <IonLoading isOpen={loading} />
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Assign Member</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent
                css={css`
                    --padding-bottom: 2rem;
                `}
            >
                <IonList>
                    {!catalogues.length ? (
                        <IonItem>
                            <h1>Empty Catalogue</h1>
                        </IonItem>
                    ) : (
                        catalogues.map((catalogue, index) => (
                            <AssignMemberSwitch
                                setLoading={setIsLoading}
                                catalogue={catalogue}
                                memberId={memberId}
                                key={index}
                            />
                        ))
                    )}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default AssignMember;
