/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { IonText } from '@ionic/react';
import { ErrorMessage } from 'formik';
import React, { useCallback } from 'react';

import BirthdatePicker from './inputs/BirthdatePicker';
import ExperiencePicker from './inputs/ExperiencePicker';
import GenderPicker from './inputs/GenderPicker';
import JerseySizePicker from './inputs/JerseySizePicker';
import { PhoneInputItem } from './inputs/PhoneInput';
import { TextInput } from './inputs/TextInput';
import UploadInput, { UploadInputProps } from './inputs/UploadInput';

interface IFieldProps<T> {
    name: T;
    label: string;
}

interface TextInputFieldProps<T> extends IFieldProps<T> {
    variant: 'textInput';
}

interface PhoneInputFieldProps<T> extends IFieldProps<T> {
    variant: 'phoneInput';
}

interface GenderPickerFieldProps<T> extends IFieldProps<T> {
    variant: 'genderPicker';
}

interface BirthdatePickerProps<T> extends IFieldProps<T> {
    variant: 'birthdatePicker';
}

interface JerseySizePickerProps<T> extends IFieldProps<T> {
    variant: 'jerseySizePicker';
}

interface ExperiencePickerProps<T> extends IFieldProps<T> {
    variant: 'experiencePicker';
}

interface UploadInputFieldProps<T> extends IFieldProps<T>, Omit<UploadInputProps, 'name'> {
    variant: 'uploadInput';
}

export type FieldProps<T> =
    | TextInputFieldProps<T>
    | PhoneInputFieldProps<T>
    | GenderPickerFieldProps<T>
    | BirthdatePickerProps<T>
    | JerseySizePickerProps<T>
    | ExperiencePickerProps<T>
    | UploadInputFieldProps<T>;

export type FieldColumnProps<T> = FieldProps<T> & {
    children?: React.ReactNode;
};

export const Field: React.FC<FieldProps<string>> = ({ name, label, ...props }) => {
    const Input = useCallback(() => {
        switch (props.variant) {
            case 'textInput':
                return <TextInput fieldName={name} label={label} />;

            case 'phoneInput':
                return <PhoneInputItem fieldName={name} />;

            case 'birthdatePicker':
                return <BirthdatePicker fieldName={name} />;

            case 'genderPicker':
                return <GenderPicker fieldName={name} />;

            case 'experiencePicker':
                return <ExperiencePicker fieldName={name} />;

            case 'jerseySizePicker':
                return <JerseySizePicker fieldName={name} />;

            case 'uploadInput': {
                const { variant, ...passThroughProps } = props;
                return <UploadInput name={name} {...passThroughProps} />;
            }

            default:
                throw new Error('field input type not implemented');
        }
    }, [label, name, props]);

    return (
        <>
            <Input />
            <ErrorMessage name={name} render={(errorMessage) => <Message color="danger">{errorMessage}</Message>} />
        </>
    );
};

const Message = styled(IonText)`
    font-size: smaller;
    text-align: left;
    padding-left: var(--ion-padding-start, 20px);
`;
