/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { IonIcon, IonItem, IonItemGroup, IonLoading, IonToast } from '@ionic/react';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';

import { useVideos } from '../../hooks/useVideos';

import CompetitionBlock from './CompetitionBlock';

interface CompetitionUploadedVideoProps {
    competition: Competition;
}

interface Toast {
    isOpen: boolean;
    message: string;
    color?: string;
}

const CompetitionUploadedVideo: React.FunctionComponent<CompetitionUploadedVideoProps> = ({ competition }) => {
    const [isDropped, setIsDropped] = useState<boolean>(false);
    const [toast, setToast] = useState<Toast>({ isOpen: false, message: '' });
    const [videos, setVideos] = useState<Video[]>();

    const [getVideos] = useVideos({
        onCompleted: (data) => {
            setVideos(data.videos);
        },
    });

    useEffect(() => {
        if (isDropped && !videos?.length) {
            getVideos({ variables: { isShow: true, competitionId: competition._id, isLimit: false } });
        }
    }, [isDropped]);

    const handleOnRemoveVideo = (id: string) => {
        setVideos(videos?.filter((video) => video._id !== id));
    };

    return (
        <IonItemGroup>
            <IonToast
                duration={3000}
                color={toast.color}
                isOpen={toast.isOpen}
                onDidDismiss={() => setToast({ message: '', isOpen: false })}
                message={toast.message}
            />

            <HeaderItem className="ion-activatable ripple-parent" onClick={() => setIsDropped(!isDropped)}>
                <div>{competition.name}</div>
                <IonIcon size="small" color="sliver" icon={isDropped ? chevronUpOutline : chevronDownOutline} />
            </HeaderItem>

            {isDropped && (
                <ContentContainer>
                    {videos?.length ? (
                        videos?.map((video, index) => (
                            <CompetitionBlock
                                key={index}
                                title={`Member ${video?.userId?.name}`}
                                video={video}
                                onRemoveVideo={handleOnRemoveVideo}
                            />
                        ))
                    ) : (
                        <p>No video</p>
                    )}
                </ContentContainer>
            )}
        </IonItemGroup>
    );
};

export default CompetitionUploadedVideo;

const HeaderItem = styled(IonItem)`
    div {
        width: 100%;
    }
    ion-icon {
        float: right;
    }
`;

const ContentContainer = styled.div`
    p {
        margin-left: 2rem;
    }
`;
