import styled from '@emotion/styled';
import {
    IonButton,
    IonIcon,
    IonItem,
    IonItemGroup,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLoading,
    IonToast,
} from '@ionic/react';
import { addCircleSharp, chevronDownOutline, chevronUpOutline, trash } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';

import { Upload } from '../../components';
import { usePastGames } from '../../hooks/usePastGames';
import { useRemovePastGame } from '../../hooks/useRemovePastGame';
import useS3Upload from '../../hooks/useS3Upload';
import { useUploadPastGame } from '../../hooks/useUploadPastGame';

interface Toast {
    isOpen: boolean;
    message: string;
    color?: string;
}

const PastGamesDropdown = () => {
    const [isDropped, setIsDropped] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pastGames, setPastGames] = useState<PastGame[]>([]);
    const [toast, setToast] = useState<Toast>({ isOpen: false, message: '' });

    const [uploadPastGame] = useUploadPastGame({
        onError(error) {
            console.log(error);
            setIsLoading(false);
            setToast({ isOpen: true, message: 'Something went wrong. Please try again' });
        },
        onCompleted(data) {
            setIsLoading(false);
            setPastGames([...pastGames, data.uploadPastGame]);
        },
    });

    const [removePastGame] = useRemovePastGame({
        onError(error) {
            console.log(error);
            setIsLoading(false);
            setToast({ isOpen: true, message: 'Something went wrong. Please try again' });
        },
        onCompleted(data) {
            setIsLoading(false);
            setPastGames(pastGames.filter((pastGame) => pastGame._id !== data.removePastGame._id));
        },
    });

    const [getPastGames] = usePastGames({
        onCompleted(data) {
            setPastGames(data.pastGames);
        },
    });

    useEffect(() => {
        if (!pastGames.length && !!isDropped) {
            getPastGames();
        }
    }, [isDropped]);

    const handleUploadVideo = useS3Upload({
        onCompleted(filename) {
            uploadPastGame({
                variables: {
                    input: {
                        url: filename,
                    },
                },
            });
        },
        onError(error) {
            console.log(error);
            setIsLoading(false);
            setToast({ isOpen: true, message: 'Something went wrong. Please try again' });
        },
    });

    const handleRemovePastGame = (id: string) => {
        setIsLoading(true);
        removePastGame({ variables: { id } });
    };

    return (
        <IonItemGroup>
            <IonLoading isOpen={isLoading} />

            <HeaderItem className="ion-activatable ripple-parent" onClick={() => setIsDropped(!isDropped)}>
                <div>Past games video</div>
                <IonIcon size="small" color="sliver" icon={isDropped ? chevronUpOutline : chevronDownOutline} />
            </HeaderItem>

            {isDropped && (
                <DropdownContent>
                    <UploadPastGameButton expand="block" fill="clear">
                        Upload video
                        <IonIcon icon={addCircleSharp} slot="icon-only" />
                        <Upload
                            type="file"
                            accept="video/*"
                            onChange={(e) => {
                                setIsLoading(true);
                                handleUploadVideo(e);
                            }}
                        />
                    </UploadPastGameButton>
                    {!!pastGames.length &&
                        pastGames.map((pastGame) => (
                            <IonItemSliding key={pastGame._id}>
                                <IonItemOptions side="end">
                                    <IonItemOption onClick={() => handleRemovePastGame(pastGame._id)}>
                                        <IonIcon icon={trash} slot="icon-only" />
                                    </IonItemOption>
                                </IonItemOptions>
                                <IonItem>{pastGame.url}</IonItem>
                            </IonItemSliding>
                        ))}
                </DropdownContent>
            )}
            <IonToast
                duration={3000}
                color={toast.color}
                isOpen={toast.isOpen}
                onDidDismiss={() => setToast({ message: '', isOpen: false })}
                message={toast.message}
            />
        </IonItemGroup>
    );
};

export default PastGamesDropdown;

const HeaderItem = styled(IonItem)`
    div {
        width: 100%;
    }

    ion-icon {
        float: right;
    }
`;

const DropdownContent = styled.div`
    margin-left: 1rem;
`;

const UploadPastGameButton = styled(IonButton)`
    margin-bottom: 0 !important;
`;
