import { IonList } from '@ionic/react';
import React from 'react';

import MembersItem from './MembersItem';

type Props = {
    data: MemberProfile[];
};

const MemberList: React.FC<Props> = ({ data }) => {
    return (
        <IonList>
            {data.map((member) => (
                <MembersItem key={member._id} {...member} />
            ))}
        </IonList>
    );
};

export default MemberList;
