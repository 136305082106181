import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const REMOVE_SIDEBAR = gql`
    mutation RemoveSidebar($id: String!) {
        removeSidebar(id: $id) {
            _id
        }
    }
`;

type TData = { removeSidebar: { _id: string } };
type TVariables = { id: string };

export const useRemoveSidebar = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(REMOVE_SIDEBAR, options);
};
