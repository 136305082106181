import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { ContentNameType } from './useContentByName';

export type UploadContentInput = {
    name: ContentNameType;
    description: string;
};

export const UPLOAD_CONTENT = gql`
    mutation UploadUrl($input: UploadContentInput!) {
        uploadContent(input: $input) {
            name
            description
        }
    }
`;

type TData = { uploadContent: Content };
type TVariables = { input: { name: ContentNameType; description: string } };

export const useUploadContent = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(UPLOAD_CONTENT, options);
};
