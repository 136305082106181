import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export interface UpdateMemberInput extends Partial<Omit<MemberDetails, '_id'>> {
    memberId: string;
    loyaltyPoint?: number;
}

const UPDATE_MEMBER_MUTATION = gql`
    mutation updateMember($input: UpdateMemberInput!) {
        updateMember(input: $input) {
            _id
            name
            picture
            age
            gender
            birthdate
            jerseySize
            experience
            tags
            createdAt
            lastActiveAt
            loyaltyPoint
        }
    }
`;

export const useUpdateMemberDetails = (
    options?: MutationHookOptions<{ updateMember: MemberDetails }, { input: UpdateMemberInput }>,
) => {
    return useMutation<{ updateMember: MemberDetails }, { input: UpdateMemberInput }>(UPDATE_MEMBER_MUTATION, options);
};
