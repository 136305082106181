import { gql, useQuery } from '@apollo/client';

export const useMemberProfileRatings = (id: string, year: number, month: number) => {
    return useQuery<{ member: MemberProfile }, { id: string; year: number; month: number }>(MEMBER_PROFILE_RATINGS, {
        variables: { id, year, month },
    });
};

const MEMBER_PROFILE_RATINGS = gql`
    query Member($id: String!, $year: Int!, $month: Int!) {
        member(id: $id) {
            _id
            ratings(year: $year, month: $month) {
                year
                month
                passing
                shooting
                dribbling
                gameAwareness
                score
                feedback
                report
            }
        }
    }
`;
