import create from 'zustand';
import { devtools } from 'zustand/middleware';

type State = {
    showEditMemberDetails: boolean;
    showEditMemberAccountDetails: boolean;
    showMemberAttendances: boolean;
    showMemberAttendanceCheckIn: boolean;
    showMemberPerformance: boolean;
    showUpdateLoyaltyPointForm: boolean;
    showMemberCheckIn: boolean;
    showProgramsHistory: boolean;
    showSelectProgramModal: boolean;
    showAddBannerModal: boolean;
    showAddSidebarModal: boolean;
};

const useModal = create<
    State & {
        toggle: (key: keyof State, visible?: boolean) => void;
    }
>(
    devtools((set) => ({
        showEditMemberDetails: false,
        showEditMemberAccountDetails: false,
        showMemberAttendances: false,
        showMemberAttendanceCheckIn: false,
        showMemberPerformance: false,
        showUpdateLoyaltyPointForm: false,
        showMemberCheckIn: false,
        showProgramsHistory: false,
        showSelectProgramModal: false,
        showAddBannerModal: false,
        showAddSidebarModal: false,
        toggle: (key, visible) =>
            // eslint-disable-next-line
            // @ts-ignore
            set((state) => ({
                [key]: typeof visible === 'undefined' ? !state[key] : visible,
            })),
    })),
);

export default useModal;
