/** @jsxImportSource @emotion/react */

import { Toast } from '@capacitor/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonLoading,
    IonTextarea,
    IonToggle,
} from '@ionic/react';
import { chevronDownOutline, chevronUpOutline, trash } from 'ionicons/icons';
import React, { useState } from 'react';

import { Upload } from '../../components';
import { useRemoveBanner } from '../../hooks/useRemoveBanner';
import useS3Upload from '../../hooks/useS3Upload';
import { useUpdateBanner } from '../../hooks/useUpdateBanner';

interface BannerInputProps {
    label: string;
    input: string;
    onUpdateInputHandler: (inputType: string, updateInput: string) => void;
}

const BannerInput: React.FunctionComponent<BannerInputProps> = ({ label, input, onUpdateInputHandler }) => {
    const [updateInput, setUpdateInput] = useState(input);
    const handleOnUpdateInput = () => {
        if (updateInput === input) return;
        onUpdateInputHandler(label.toLowerCase(), updateInput);
    };
    return (
        <IonItem>
            <IonLabel position="stacked">{label}</IonLabel>
            <IonTextarea
                autoGrow
                value={updateInput}
                onIonChange={(e) => setUpdateInput(e.detail.value || '')}
                onIonBlur={handleOnUpdateInput}
            />
        </IonItem>
    );
};
interface BannerDropdownProps {
    banner: Banner;
    onRemoveBanner: (bannerId: string) => void;
    onUpdateBanner: (banner: Banner) => void;
}

const BannerDropdown: React.FunctionComponent<BannerDropdownProps> = ({ banner, onRemoveBanner, onUpdateBanner }) => {
    const { _id, title, subtitle, link, imageUrl, isActive } = banner;
    const [isDropped, setIsDropped] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [removeBanner] = useRemoveBanner({
        onCompleted: (data) => {
            onRemoveBanner(data.removeBanner._id);
        },
        onError(err) {
            Toast.show({ text: err.message });
        },
    });

    const [updateBanner] = useUpdateBanner({
        onCompleted: (data) => {
            onUpdateBanner(data.updateBanner);
            Toast.show({ text: 'Update successfully' });
        },
        onError: (err) => {
            Toast.show({ text: err.message });
        },
    });

    const handleOnUpdateBanner = (key: string, value: string) => {
        if (!value.length && key !== 'link') {
            Toast.show({ text: 'Fields are required' });
            return;
        }
        updateBanner({ variables: { id: banner._id, input: { key, value } } });
    };

    const handleUploadImage = useS3Upload({
        onCompleted(filename) {
            handleOnUpdateBanner('imageUrl', filename);
            setLoading(false);
        },
        onError(err) {
            setLoading(false);
            Toast.show({ text: err.message });
        },
    });
    return (
        <IonItemGroup>
            <IonItemSliding
                css={css`
                    display: inline-flex;
                `}
            >
                <IonItemOptions side="end">
                    <IonItemOption onClick={() => removeBanner({ variables: { id: _id } })}>
                        <IonIcon icon={trash} slot="icon-only" />
                    </IonItemOption>
                </IonItemOptions>

                <HeaderItem className="ion-activatable ripple-parent" onClick={() => setIsDropped(!isDropped)}>
                    <div>{title}</div>
                    <IonIcon size="small" color="sliver" icon={isDropped ? chevronUpOutline : chevronDownOutline} />
                </HeaderItem>
            </IonItemSliding>

            {isDropped && (
                <ContentContainer>
                    <IonLoading isOpen={loading} />

                    <BannerInput label="Title" input={title} onUpdateInputHandler={handleOnUpdateBanner} />
                    <BannerInput label="Subtitle" input={subtitle} onUpdateInputHandler={handleOnUpdateBanner} />
                    <BannerInput label="Link" input={link || ''} onUpdateInputHandler={handleOnUpdateBanner} />

                    <IonItem>
                        <IonLabel position={imageUrl ? 'floating' : 'fixed'}>Image</IonLabel>
                        <IonInput value={imageUrl} readonly />

                        <Upload
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                setLoading(true);
                                handleUploadImage(e);
                            }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Active</IonLabel>

                        <IonToggle
                            css={css`
                                margin: auto;
                            `}
                            slot="end"
                            color="success"
                            checked={isActive}
                            name="isActive"
                            onClick={() => handleOnUpdateBanner('isActive', JSON.stringify(!isActive))}
                        />
                    </IonItem>
                </ContentContainer>
            )}
        </IonItemGroup>
    );
};

export default BannerDropdown;

const HeaderItem = styled(IonItem)`
    width: 100%;
    div {
        width: 100%;
    }
    ion-icon {
        float: right;
    }
`;

const ContentContainer = styled.div`
    p {
        margin-left: 2rem;
    }
`;
