import { IonAlert } from '@ionic/react';
import React from 'react';
import { Toast } from '@capacitor/core';

import { MEMBER_PROFILE } from '../hooks/useMemberProfile';
import { useUpdateMemberDetails } from '../hooks/useUpdateMemberDetails';
import { useMember } from '../features/Member';

import { useModal } from '.';

interface UpdateFields {
    loyaltyPoint: number;
}

const UpdateLoyaltyPointModal: React.FC = () => {
    const { _id: memberId, loyaltyPoint } = useMember();
    const { showUpdateLoyaltyPointForm, toggle } = useModal();
    const [updateLoyaltyPoints] = useUpdateMemberDetails({
        onError: () => {
            Toast.show({ text: 'Something went wrong. Please try again' });
        },
    });

    return (
        <IonAlert
            isOpen={showUpdateLoyaltyPointForm}
            onDidDismiss={() => toggle('showUpdateLoyaltyPointForm')}
            header="Update Loyalty Point for Member"
            inputs={[
                {
                    name: 'loyaltyPoint',
                    type: 'number',
                    placeholder: 'Loyalty Points',
                    value: loyaltyPoint,
                },
            ]}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: 'Confirm',
                    handler({ loyaltyPoint }: UpdateFields) {
                        updateLoyaltyPoints({
                            variables: { input: { memberId, loyaltyPoint: parseInt(loyaltyPoint.toString()) } },
                            update: (cache) => {
                                const existedMember = cache.readQuery<{ member: MemberProfile }>({
                                    query: MEMBER_PROFILE,
                                    variables: { id: memberId },
                                });
                                if (existedMember) {
                                    cache.writeQuery<{ member: MemberProfile }>({
                                        query: MEMBER_PROFILE,
                                        data: {
                                            member: {
                                                ...existedMember.member,
                                                loyaltyPoint: parseInt(loyaltyPoint.toString()),
                                            },
                                        },
                                    });
                                }
                            },
                        });
                    },
                },
            ]}
        />
    );
};

export default UpdateLoyaltyPointModal;
