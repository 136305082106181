/** @jsxImportSource @emotion/react */
import { Toast } from '@capacitor/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    IonAlert,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonListHeader,
    IonRow,
    IonText,
} from '@ionic/react';
import dayjs from 'dayjs';
import { removeCircleOutline, addCircleOutline } from 'ionicons/icons';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PROGRAM_DETAIL, useProgramsDetail } from '../../hooks/useProgramsDetail';
import { useUpdateProgramQuantity } from '../../hooks/useUpdateProgramQuantity';
import { ProgramsHistoryModal, useModal } from '../../modals';
import { client } from '../../services/apollo';

export type MemberProgram = Omit<Program, 'link' | 'startDate' | 'startTime' | 'address'> & {
    quantity: number;
};
type ProgramItemProp = {
    program: MemberProgram;
    memberId?: string;
};

const ProgramItem: React.FC<ProgramItemProp> = ({ program, memberId }) => {
    const { _id, name, endDate, type, fee, quantity } = program;
    const [changeQuantity, setChangeQuantity] = useState(quantity);
    const didMount = useRef(false);
    const [showAlert, setShowAlert] = useState(false);
    const [updateProgramQuantity] = useUpdateProgramQuantity({
        onCompleted: () => {
            Toast.show({ text: 'Update successfully!' });
            if (changeQuantity === 0) setShowAlert(true);
        },
        onError: (error) => {
            Toast.show({ text: error.message });
        },
        update: (cache) => {
            const existedProgram =
                cache.readQuery<{ programsDetail: MemberProgram[] }>({
                    query: PROGRAM_DETAIL,
                    variables: { id: memberId },
                })?.programsDetail || [];
            cache.writeQuery<{ programsDetail: MemberProgram[] }>({
                query: PROGRAM_DETAIL,
                variables: { id: memberId },
                data: {
                    programsDetail: existedProgram.map((program) => {
                        if (program._id === _id) {
                            return {
                                ...program,
                                quantity: changeQuantity,
                            };
                        }
                        return {
                            ...program,
                        };
                    }),
                },
            });
        },
    });

    useEffect(() => {
        if (didMount.current && quantity !== changeQuantity && changeQuantity !== 0)
            updateProgramQuantityDebounce(changeQuantity);
        else didMount.current = true;
        if (changeQuantity === 0) setShowAlert(true);
    }, [changeQuantity]);

    useEffect(() => {
        setChangeQuantity(quantity);
    }, [quantity]);

    const updateProgramQuantityDebounce = useCallback(
        debounce((newQuantity: number) => {
            type === 'session' &&
                memberId &&
                updateProgramQuantity({
                    variables: {
                        id: memberId,
                        program: { programId: _id, quantity: newQuantity },
                    },
                });
        }, 500),
        [],
    );

    return (
        <>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass="my-custom-class"
                header={'Alert'}
                message={'Are your sure to change quantity to 0? It will be moved to History'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setChangeQuantity(1);
                        },
                    },
                    {
                        text: 'OK',
                        handler: () => {
                            updateProgramQuantityDebounce(changeQuantity);
                        },
                    },
                ]}
            />
            <IonItemCustomPadding lines="full">
                <IonLabel slot="start">
                    <IonCardTitle
                        css={css`
                            font-size: 20px;
                            white-space: normal !important;
                        `}
                    >
                        {name}
                    </IonCardTitle>
                    <IonCardSubtitle>${fee}</IonCardSubtitle>
                </IonLabel>

                {type === 'session' ? (
                    <>
                        <SpaceBetweenRowWithLargerFont slot="end">
                            <IonIcon
                                color="danger"
                                icon={removeCircleOutline}
                                onClick={() => {
                                    setChangeQuantity(changeQuantity - 1 < 0 ? 0 : changeQuantity - 1);
                                }}
                            />
                            <IonText
                                css={css`
                                    margin: 0 10px;
                                `}
                            >
                                {changeQuantity}
                            </IonText>
                            <IonIcon
                                color="danger"
                                icon={addCircleOutline}
                                onClick={() => setChangeQuantity(changeQuantity + 1)}
                            />
                        </SpaceBetweenRowWithLargerFont>
                    </>
                ) : (
                    <IonCol
                        slot="end"
                        css={css`
                            text-align: -webkit-right;
                            margin: 0;
                        `}
                    >
                        <FlexEndRow>End at</FlexEndRow>
                        <FlexEndRow
                            css={css`
                            font-size: 13px;
                        }
                        `}
                        >
                            {endDate}
                        </FlexEndRow>
                    </IonCol>
                )}
            </IonItemCustomPadding>
        </>
    );
};

const MemberPrograms = () => {
    const { id } = useParams<{ id: string }>();
    const { data } = useProgramsDetail(id);
    const { toggle } = useModal();

    const { cache } = client;
    const programs =
        cache.readQuery<{ programsDetail: MemberProgram[] }>({
            query: PROGRAM_DETAIL,
            variables: { id },
        })?.programsDetail || [];

    const getHistory = () => {
        return programs.filter(
            (program) =>
                (program.quantity <= 0 && program.type === 'session') ||
                (program.endDate && program.type === 'monthly' && isExpired(program.endDate, program.endTime)),
        );
    };

    function isExpired(date: string, time: string) {
        return Date.parse(new Date().toLocaleString()) > Date.parse(date + ' ' + time);
    }

    useEffect(() => {
        getHistory();
    }, [programs]);

    return (
        <>
            <ProgramsHistoryModal programsHistory={getHistory()} />
            <IonList>
                <IonListHeader>
                    <IonLabel>Registered Programs</IonLabel>
                    <IonItem
                        css={css`
                            font-size: 14px;
                            font-weight: normal;
                        `}
                        lines="none"
                        disabled={getHistory() === undefined || getHistory().length <= 0}
                        onClick={() => toggle('showProgramsHistory')}
                    >
                        <IonLabel
                            css={css`
                                margin: 10px 0 6px 0;
                                white-space: normal !important;
                            `}
                            color="tertiary"
                        >
                            History
                        </IonLabel>
                    </IonItem>
                </IonListHeader>
                {data?.programsDetail.length ? (
                    <>
                        <IonList>
                            {programs
                                .filter((program) => program.type === 'session' && program.quantity > 0)
                                .map((program) => (
                                    <ProgramItem key={program._id} program={program} memberId={id}></ProgramItem>
                                ))}
                        </IonList>
                        <IonList>
                            {programs
                                .filter(
                                    (program) =>
                                        program.type === 'monthly' &&
                                        (program.endDate ? !isExpired(program.endDate, program.endTime) : true),
                                )
                                .map((program) => (
                                    <ProgramItem key={program._id} program={program}></ProgramItem>
                                ))}
                        </IonList>
                    </>
                ) : (
                    <IonItem>No program found</IonItem>
                )}
            </IonList>
            <IonItemDivider />
        </>
    );
};

export default MemberPrograms;

const SpaceBetweenRowWithLargerFont = styled(IonRow)`
    font-size: 25px;
    justify-content: space-between;
    width: max-content;
    margin: 0
}
`;

const FlexEndRow = styled(IonRow)`
    width: max-content;
`;

const IonItemCustomPadding = styled(IonItem)`
    &::part(native) {
        padding: 10px 0 10px 20px;
    }
`;
