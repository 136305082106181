import { gql, useQuery } from '@apollo/client';

export const useMemberProfileProgress = (id: string) => {
    return useQuery<{ member: MemberProfile }, { id: string }>(MEMBER_PROFILE_PROGRESS, {
        variables: { id },
    });
};

export const MEMBER_PROFILE_PROGRESS = gql`
    query Member($id: String!) {
        member(id: $id) {
            _id
            progress {
                year
                month
                score
                gameAwareness
                dribbling
                shooting
                passing
            }
        }
    }
`;
