import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const REMOVE_PROGRAM = gql`
    mutation RemoveProgram($id: String!) {
        removeProgram(id: $id) {
            _id
        }
    }
`;

type TData = { removeProgram: { _id: string } };
type TVariables = { id: string };

export const useRemoveProgram = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(REMOVE_PROGRAM, options);
};
