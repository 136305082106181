import { gql, MutationHookOptions, useMutation } from '@apollo/client';

interface ProgramInput {
    programId: string;
    quantity: number;
}

interface ApprovalRequestProgramInput {
    programs: ProgramInput[];
}

const APPROVE_PAYMENT_APPROVAL_REQUEST = gql`
    mutation ApprovePaymentApprovalRequest($id: String!, $input: ApprovalRequestProgramInput!) {
        approvePaymentApprovalRequest(id: $id, input: $input) {
            _id
            status
        }
    }
`;

type TData = { approvePaymentRequest: boolean };
type TVariables = { id: string; input: ApprovalRequestProgramInput };

const useApprovePaymentApprovalRequest = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(APPROVE_PAYMENT_APPROVAL_REQUEST, options);
};

export default useApprovePaymentApprovalRequest;
