import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export type CreateBannerInput = Omit<Banner, '_id' | 'isActive'>;

const CREATE_BANNER = gql`
    mutation CreateBanner($input: CreateBannerInput!) {
        createBanner(input: $input) {
            _id
            title
            link
            imageUrl
            subtitle
            isActive
        }
    }
`;

type TData = { createBanner: Banner };
type TVariables = { input: CreateBannerInput };

export const useCreateBanner = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(CREATE_BANNER, options);
};
