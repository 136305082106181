import { IonInput, IonItem, IonLabel } from '@ionic/react';
import { useField } from 'formik';
import React from 'react';

export const TextInput: React.FC<{ fieldName: string; label: string }> = ({ fieldName, label }) => {
    const [{ value, onBlur, onChange }] = useField(fieldName);

    return (
        <IonItem>
            <IonLabel position="floating">{label}</IonLabel>
            <IonInput name={fieldName} onIonChange={onChange} onIonBlur={onBlur} {...{ value }} />
        </IonItem>
    );
};
