import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';

const GET_PAST_GAMES = gql`
    query GetPastGames {
        pastGames {
            _id
            url
        }
    }
`;

type TData = { pastGames: PastGame[] };

export const usePastGames = (options?: QueryHookOptions<TData>) => {
    return useLazyQuery<TData>(GET_PAST_GAMES, options);
};
