import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const UPDATE_PROGRAM = gql`
    mutation UpdateProgram($id: String!, $input: UpdateProgramInput!) {
        updateProgram(id: $id, input: $input) {
            _id
            name
            type
            link
            address
            fee
            startDate
            endDate
            startTime
            endTime
        }
    }
`;

type TData = { updateProgram: Program };
type TVariables = { id: string; input: { key: string; value: string } };

export const useUpdateProgram = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(UPDATE_PROGRAM, options);
};
