/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonCardSubtitle, IonCardTitle, IonCol, IonIcon, IonItem, IonLabel, IonModal, IonRow } from '@ionic/react';
import { reorderTwoOutline } from 'ionicons/icons';
import React from 'react';

import { MemberProgram } from '../features/Member/MemberPrograms';

import { useModal } from '.';

const UnusedProgram: React.FC<{ program: MemberProgram }> = ({ program }) => {
    const { name, endDate, type, fee } = program;
    return (
        <IonItem
            lines="full"
            css={css`
                color: var(--ion-color-step-600, #666666);
            `}
        >
            <IonLabel slot="start">
                <IonCardTitle
                    css={css`
                        color: var(--ion-color-step-600, #666666);
                        font-size: 20px;
                        white-space: normal !important;
                    `}
                >
                    {name}
                </IonCardTitle>
                <IonCardSubtitle>${fee}</IonCardSubtitle>
            </IonLabel>
            {type === 'session' ? null : (
                <IonCol slot="end">
                    <FlexEndRow>End at</FlexEndRow>
                    <FlexEndRow
                        css={css`
                            font-size: 13px;
                        }
                        `}
                    >
                        {endDate}
                    </FlexEndRow>
                </IonCol>
            )}
        </IonItem>
    );
};

const ProgramsHistoryModal: React.FC<{ programsHistory: MemberProgram[] }> = ({ programsHistory }) => {
    const { toggle, showProgramsHistory } = useModal();

    return (
        <Container
            swipeToClose
            isOpen={showProgramsHistory}
            onDidDismiss={() => toggle('showProgramsHistory')}
            backdropDismiss
        >
            <Handle>
                <IonIcon icon={reorderTwoOutline} />
            </Handle>

            {programsHistory && programsHistory.map((program) => <UnusedProgram program={program} key={program._id} />)}
        </Container>
    );
};

export default ProgramsHistoryModal;

const Container = styled(IonModal)`
    color: var(--ion-text-color);

    .modal-wrapper[role='dialog'] {
        margin-top: auto;
        height: 640px;
        border-radius: 20px;
    }

    @media only screen and (max-width: 600px) {
        .modal-wrapper[role='dialog'] {
            height: 520px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .ion-page {
        justify-content: normal;
    }
`;

const Handle = styled.div`
    text-align: center;
    padding: 1rem;
`;

const FlexEndRow = styled(IonRow)`
    justify-content: flex-end;
    width: max-content;
`;
