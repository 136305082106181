import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export type CreateSidebarInput = Omit<Sidebar, '_id'>;

const CREATE_SIDEBAR = gql`
    mutation CreateSidebar($input: CreateSidebarInput!) {
        createSidebar(input: $input) {
            _id
            title
            link
        }
    }
`;

type TData = { createSidebar: Sidebar };
type TVariables = { input: CreateSidebarInput };

export const useCreateSidebar = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(CREATE_SIDEBAR, options);
};
