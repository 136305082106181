import { gql, useQuery } from '@apollo/client';

export const GET_LIST_CATALOGUES = gql`
    query GetListCatalogues {
        catalogues {
            _id
            name
            members {
                memberId
                difficulties
            }
            privacyPoint
            videoDemo
            thumbnail
        }
    }
`;

export const useCatalogues = () => {
    return useQuery<{ catalogues: Catalogue[] }>(GET_LIST_CATALOGUES);
};
