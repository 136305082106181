import dayjs from 'dayjs';
import Calendar from 'dayjs/plugin/calendar';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import IsSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(Calendar);
dayjs.extend(CustomParseFormat);
dayjs.extend(RelativeTime);
dayjs.extend(IsSameOrBefore);
