import { gql, useQuery } from '@apollo/client';
type ProgramDetail = Omit<Program, 'link' | 'startDate' | 'startTime' | 'address'> & {
    quantity: number;
};

export const useProgramsDetail = (id: string) => {
    return useQuery<{ programsDetail: ProgramDetail[] }, { id: string }>(PROGRAM_DETAIL, {
        variables: { id },
    });
};

export const PROGRAM_DETAIL = gql`
    query ProgramsDetail($id: String!) {
        programsDetail(id: $id) {
            _id
            quantity
            name
            fee
            endDate
            type
            endTime
        }
    }
`;
