import { gql, useQuery } from '@apollo/client';

export const GET_CONTENTS = gql`
    query GetContents {
        contents {
            name
            description
        }
    }
`;

type TData = { contents: Content[] };

export const useContents = () => {
    return useQuery<TData>(GET_CONTENTS);
};
