/** @jsxImportSource @emotion/react */
import { Toast } from '@capacitor/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonIcon, IonItem, IonItemGroup } from '@ionic/react';
import { checkmarkCircle, chevronDownOutline, chevronUpOutline, ellipseOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';

import { useUpdateCatalogue } from '../../hooks/useUpdateCatalogue';

const difficulties = ['Beginner', 'Intermediate', 'Advanced'];
interface AssignMemberProps {
    catalogue: Pick<Catalogue, '_id' | 'members' | 'name'>;
    memberId: string;
    setLoading: (loading: boolean) => void;
}
interface CatalogueDifficultyProps {
    difficulty: string;
    onAssign: (difficulty: string) => void;
    isAssigned: boolean;
}

const CatalogueDifficulty: React.FC<CatalogueDifficultyProps> = ({ difficulty, onAssign, isAssigned }) => {
    return (
        <IonItemGroup onClick={() => onAssign(difficulty.toLowerCase())}>
            <HeaderItem
                css={css`
                    padding-left: 15px;
                `}
            >
                <div>{difficulty}</div>
                <IonIcon color="sliver" icon={isAssigned ? checkmarkCircle : ellipseOutline} />
            </HeaderItem>
        </IonItemGroup>
    );
};

const AssignMemberSwitch: React.FC<AssignMemberProps> = ({ setLoading, catalogue, memberId }): React.ReactElement => {
    const [isDropped, setIsDropped] = useState<boolean>(false);
    const [useUpdateCatalogueRequest, { loading }] = useUpdateCatalogue({
        onError: (error) => {
            Toast.show({ text: error.message });
        },
        refetchQueries: ['GetListCatalogues'],
    });

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    const handleOnAssign = (difficulty: string) => {
        useUpdateCatalogueRequest({
            variables: {
                id: catalogue._id,
                input: { memberId, difficulty },
            },
        });
    };

    const checkIsAssigned = (difficulty: string) => {
        if (catalogue.members.length)
            return !!catalogue.members
                .find((member) => member.memberId === memberId)
                ?.difficulties.includes(difficulty.toLowerCase());
        return false;
    };
    return (
        <>
            <HeaderItem className="ion-activatable ripple-parent" onClick={() => setIsDropped(!isDropped)}>
                <div>{catalogue.name}</div>
                <IonIcon size="small" color="sliver" icon={isDropped ? chevronUpOutline : chevronDownOutline} />
            </HeaderItem>
            {isDropped &&
                difficulties.map((difficulty, index) => (
                    <CatalogueDifficulty
                        key={index}
                        difficulty={difficulty}
                        onAssign={handleOnAssign}
                        isAssigned={checkIsAssigned(difficulty)}
                    />
                ))}
        </>
    );
};

export default AssignMemberSwitch;

const HeaderItem = styled(IonItem)`
    div {
        width: 100%;
    }
    ion-icon {
        float: right;
    }
`;
