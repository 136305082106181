/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import React, { useMemo, useState } from 'react';
import {
    IonButton,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonModal,
} from '@ionic/react';
import { reorderTwoOutline } from 'ionicons/icons';
import { Formik } from 'formik';
import { Toast } from '@capacitor/core';

import { Field, FieldColumnProps, Flex, Upload } from '../components';
import { CreateBannerInput, useCreateBanner } from '../hooks/useCreateBanner';
import useS3Upload from '../hooks/useS3Upload';

import { useModal } from '.';

const AddBannerModal: React.FC<{ onCreateBanner: (newBanner: Banner) => void }> = ({ onCreateBanner }) => {
    const [imageUrl, setImageUrl] = useState('');
    const { toggle, showAddBannerModal } = useModal();
    const fields = useMemo(() => {
        const BANNER_FIELDS: FieldColumnProps<keyof CreateBannerInput>[] = [
            {
                name: 'title',
                label: 'Title*',
                variant: 'textInput',
            },
            {
                name: 'subtitle',
                label: 'Subtitle*',
                variant: 'textInput',
            },
            {
                name: 'link',
                label: 'URL',
                variant: 'textInput',
            },
        ];

        return BANNER_FIELDS.map(({ name, ...props }) => <Field key={name} {...{ name }} {...props} />);
    }, []);

    const handleUploadImage = useS3Upload({
        onCompleted(filename) {
            setImageUrl(filename);
        },
        onError(err) {
            Toast.show({ text: err.message });
        },
    });
    const [createBanner] = useCreateBanner({
        onCompleted(data) {
            toggle('showAddBannerModal', false);
            setImageUrl('');
            Toast.show({ text: 'Banner created!' });
            onCreateBanner(data.createBanner);
        },
        onError(err) {
            Toast.show({ text: err.message });
        },
    });
    return (
        <Container
            swipeToClose
            isOpen={showAddBannerModal}
            onDidDismiss={() => toggle('showAddBannerModal', false)}
            backdropDismiss
        >
            <Handle>
                <IonIcon icon={reorderTwoOutline} />
            </Handle>
            <Formik
                validateOnBlur
                initialValues={{
                    title: '',
                    subtitle: '',
                    link: '',
                }}
                onSubmit={(values) => {
                    for (const [key, value] of Object.entries(values)) {
                        if (!value.length && key !== 'link') {
                            Toast.show({ text: 'Please fill out all required fields' });
                            return;
                        }
                    }
                    createBanner({ variables: { input: { ...values, imageUrl: imageUrl } } });
                }}
            >
                {({ handleSubmit }) => (
                    <ContentContainer>
                        <IonList>
                            <IonListHeader>Add new banner</IonListHeader>

                            {fields}
                            <IonItem>
                                <IonLabel>Image*</IonLabel>
                                <IonInput value={imageUrl} readonly />
                                <Upload
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        handleUploadImage(e);
                                    }}
                                />
                            </IonItem>
                        </IonList>

                        <IonButton onClick={() => handleSubmit()}>Save</IonButton>
                        <IonButton fill="outline" onClick={() => toggle('showAddBannerModal', false)}>
                            Cancel
                        </IonButton>
                    </ContentContainer>
                )}
            </Formik>
        </Container>
    );
};

export default AddBannerModal;

const Container = styled(IonModal)`
    color: var(--ion-text-color);

    .modal-wrapper[role='dialog'] {
        margin-top: auto;
        height: 640px;
        border-radius: 20px;
    }

    @media only screen and (max-width: 600px) {
        .modal-wrapper[role='dialog'] {
            height: 520px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .ion-page {
        justify-content: normal;
    }
`;

const Handle = styled.div`
    text-align: center;
    padding: 1rem;
`;

const ContentContainer = styled(Flex)`
    height: 100%;
    padding: 1rem;

    ion-button:first-of-type {
        margin-top: auto;
    }
`;
