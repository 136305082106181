import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const REMOVE_COMPETITION = gql`
    mutation RemoveCompetition($id: String!) {
        removeCompetition(id: $id) {
            _id
            name
            description
        }
    }
`;

type TData = { removeCompetition: Competition };

export const useRemoveCompetition = (options?: MutationHookOptions<TData>) => {
    return useMutation<TData, { id?: string }>(REMOVE_COMPETITION, options);
};
