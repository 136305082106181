import { IonAlert, IonToast } from '@ionic/react';
import React, { useContext, useState } from 'react';

import { useMember } from '../features/Member';
import { useCheckInMutation } from '../hooks/useCheckInMutation';
import { client } from '../services/apollo';
import { PROGRAM_DETAIL } from '../hooks/useProgramsDetail';
import { MEMBER_PROFILE_ATTENDANCES } from '../hooks/useMemberProfileAttendances';
import { MemberProgram } from '../features/Member/MemberPrograms';

import { AttendanceContext } from './MemberAttendanceModal';

import { useModal } from '.';

const MemberAttendanceCheckInModal: React.FC<{ setTotalCount: React.Dispatch<React.SetStateAction<number>> }> = ({
    setTotalCount,
}) => {
    const { showMemberAttendanceCheckIn, showSelectProgramModal, toggle } = useModal();
    const { date } = useContext(AttendanceContext);

    const { _id: id, name } = useMember();
    const [showToast, setShowToast] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const { cache } = client;
    const programs =
        cache.readQuery<{ programsDetail: MemberProgram[] }>({
            query: PROGRAM_DETAIL,
            variables: { id },
        })?.programsDetail || [];

    const programTypes = Array.from(new Set(programs.map((program) => program.type)));
    const isAnySessionProgram = programTypes.includes('session');

    const [checkInMember, { error }] = useCheckInMutation({
        onCompleted() {
            setShowToast(true);
            const totalCount =
                cache.readQuery<{ member: MemberProfile }>({
                    query: MEMBER_PROFILE_ATTENDANCES,
                    variables: {
                        id,
                        year: date.year(),
                        month: date.month(),
                    },
                })?.member.attendances.totalCount || 0;
            setTotalCount(totalCount + 1);
        },
        onError() {
            setShowAlert(true);
        },
        refetchQueries: [
            {
                query: MEMBER_PROFILE_ATTENDANCES,
                variables: {
                    id,
                    year: date.year(),
                    month: date.month(),
                },
            },
        ],
    });

    const formattedDate = date.format('YYYY-MM-D');

    return (
        <>
            <IonAlert
                header={`Check In ${name}`}
                subHeader={formattedDate}
                isOpen={showMemberAttendanceCheckIn}
                message={!programs.length ? 'This member has not registered yet' : undefined}
                onDidDismiss={() => toggle('showMemberAttendanceCheckIn', false)}
                inputs={programTypes.map((type, index) => {
                    return {
                        type: 'radio',
                        value: type,
                        label: `For ${type} program`,
                        checked: index === 0,
                    };
                })}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'OK',
                        handler: (type) => {
                            if (!programs.length) {
                                return toggle('showMemberAttendanceCheckIn', false);
                            }
                            if (type === 'session') {
                                toggle('showSelectProgramModal', true);
                                return toggle('showMemberAttendanceCheckIn', false);
                            }
                            checkInMember({
                                variables: { memberId: id, date: formattedDate, programId: '' },
                            });
                        },
                    },
                ]}
            />

            <IonAlert
                isOpen={showSelectProgramModal}
                onDidDismiss={() => toggle('showSelectProgramModal', false)}
                header="Select a program"
                message={!isAnySessionProgram ? 'This member is not registered any Session Program yet' : ''}
                inputs={programs
                    .filter((program) => program.type === 'session')
                    .map((program, index) => {
                        return {
                            type: 'radio',
                            label: program.name,
                            value: program._id,
                            name: 'programId',
                            checked: index === 0,
                        };
                    })}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'Save',
                        handler: (programId: string) => {
                            if (!isAnySessionProgram) {
                                return toggle('showSelectProgramModal', false);
                            }
                            checkInMember({
                                variables: { memberId: id, date: formattedDate, programId },
                                update: (cache) => {
                                    cache.writeQuery<{ programsDetail: MemberProgram[] }>({
                                        query: PROGRAM_DETAIL,
                                        variables: { id },
                                        data: {
                                            programsDetail: programs.map((program) => {
                                                if (program._id === programId) {
                                                    return {
                                                        ...program,
                                                        quantity: program.quantity - 1,
                                                    };
                                                }
                                                return {
                                                    ...program,
                                                };
                                            }),
                                        },
                                    });
                                },
                            });
                        },
                    },
                ]}
            />

            <IonToast
                duration={2000}
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message="Attendance is taken successfully."
            />

            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header="Error"
                message={error?.graphQLErrors.map((err) => err.message).join(' ')}
                buttons={['OK']}
            />
        </>
    );
};

export default MemberAttendanceCheckInModal;
