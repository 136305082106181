import { CognitoUser } from '@aws-amplify/auth';
import { IonLoading } from '@ionic/react';
import { Auth } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';

interface AuthState {
    isAuthenticated: boolean | null;
    user?: CognitoUser;
    userAttributes: Record<string, string>;
}

const defaultAuthState = {
    isAuthenticated: null,
    userAttributes: {},
};

const AuthContext = React.createContext<AuthState>(defaultAuthState);

export const AuthProvider: React.FC = ({ children }) => {
    const [state, setState] = useState<AuthState>(defaultAuthState);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setState({
                    isAuthenticated: true,
                    user,
                    userAttributes: user.attributes,
                });
            })
            .catch((err) => {
                setState({
                    isAuthenticated: false,
                    userAttributes: {},
                });
            });
    }, []);

    if (state.isAuthenticated === null) {
        return <IonLoading isOpen />;
    }
    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    return context;
};
