import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const UPDATE_COMPETITION = gql`
    mutation UpdateCompetition($id: String!, $input: UpdateCompetitionInput!) {
        updateCompetition(id: $id, input: $input) {
            _id
            name
            description
            videoDemo
            thumbnail
        }
    }
`;

type TData = { updateCompetition: Competition };
type TVariables = { id: string; input: { name: string; value: string } };

export const useUpdateCompetition = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(UPDATE_COMPETITION, options);
};
