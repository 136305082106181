/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonLoading } from '@ionic/react';
import { chevronUpOutline, chevronDownOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { Toast } from '@capacitor/core';

import { Upload } from '../../components';
import useS3Upload from '../../hooks/useS3Upload';
import { useUpdateCatalogueDetail } from '../../hooks/useUpdateCatalogueDetail';

const CatalogueDetailDropdown: React.FunctionComponent<{ catalogue: Catalogue }> = ({ catalogue }) => {
    const [isDropped, setIsDropped] = useState<boolean>(false);
    const [videoDemo, setVideoDemo] = useState<string>(catalogue.videoDemo);
    const [thumbnail, setThumbnail] = useState<string>(catalogue.thumbnail);
    const [points, setPoints] = useState<number>(catalogue.privacyPoint);
    const [loading, setLoading] = useState(false);

    const [updateCatalogueDetail] = useUpdateCatalogueDetail({
        onCompleted: (data) => {
            setVideoDemo(data.updateCatalogueDetail.videoDemo);
            setThumbnail(data.updateCatalogueDetail.thumbnail);
            setLoading(false);
            Toast.show({ text: 'Updated successfully!' });
        },
        onError: (err) => {
            setLoading(false);
            Toast.show({ text: err.message });
        },
    });

    const handleUploadVideoDemo = useS3Upload({
        onCompleted(filename) {
            updateCatalogueDetail({ variables: { id: catalogue._id, input: { name: 'videoDemo', value: filename } } });
        },
        onError(err) {
            setLoading(false);
            err.message && Toast.show({ text: err.message });
        },
    });

    const handleUploadThumbnail = useS3Upload({
        onCompleted(filename) {
            updateCatalogueDetail({ variables: { id: catalogue._id, input: { name: 'thumbnail', value: filename } } });
        },
        onError(err) {
            setLoading(false);
            err.message && Toast.show({ text: err.message });
        },
    });

    return (
        <IonItemGroup>
            <HeaderItem className="ion-activatable ripple-parent" onClick={() => setIsDropped(!isDropped)}>
                <div>{catalogue.name}</div>
                <IonIcon size="small" color="sliver" icon={isDropped ? chevronUpOutline : chevronDownOutline} />
            </HeaderItem>
            {isDropped && (
                <ContentContainer>
                    <IonLoading isOpen={loading} />

                    <IonItem>
                        <IonLabel position={videoDemo ? 'floating' : 'fixed'}>Demo Video</IonLabel>
                        <IonInput value={videoDemo} readonly />

                        <Upload
                            type="file"
                            accept="video/*"
                            onChange={(e) => {
                                setLoading(true);
                                handleUploadVideoDemo(e);
                            }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position={thumbnail ? 'floating' : 'fixed'}>Thumbnail</IonLabel>
                        <IonInput value={thumbnail} readonly />

                        <Upload
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                setLoading(true);
                                handleUploadThumbnail(e);
                            }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Privacy Points</IonLabel>
                        <IonInput
                            css={css`
                                text-align: right;
                            `}
                            value={points}
                            onIonChange={(e) =>
                                setPoints(
                                    e.detail.value && parseFloat(e.detail.value) >= 1 ? parseFloat(e.detail.value) : 1,
                                )
                            }
                            onIonBlur={() => {
                                setLoading(true);
                                updateCatalogueDetail({
                                    variables: {
                                        id: catalogue._id,
                                        input: { name: 'privacyPoint', value: points.toString() },
                                    },
                                });
                            }}
                            type="number"
                            min="1"
                            step="0.25"
                        />
                    </IonItem>
                </ContentContainer>
            )}
        </IonItemGroup>
    );
};

export default CatalogueDetailDropdown;

const ContentContainer = styled.div`
    p {
        margin-left: 2rem;
    }
`;

const HeaderItem = styled(IonItem)`
    div {
        width: 100%;
    }
    ion-icon {
        float: right;
    }
`;
