import { IonDatetime, IonItem, IonLabel } from '@ionic/react';
import { useField } from 'formik';
import React from 'react';

type Props = {
    fieldName?: string;
};

export const BirthdatePicker: React.FC<Props> = ({ fieldName = 'birthdate' }) => {
    const [{ value }, , { setValue }] = useField(fieldName);

    return (
        <IonItem>
            <IonLabel position="floating">Date of Birth</IonLabel>
            <IonDatetime
                displayFormat="DD/MM/YYYY"
                placeholder="Select"
                value={value}
                onIonChange={(e) => setValue(e.detail.value)}
            />
        </IonItem>
    );
};

export default BirthdatePicker;
