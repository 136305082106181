import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { scanSharp } from 'ionicons/icons';
import React, { useState } from 'react';

import { MemberDetails, MemberGuard, MemberRelatedProfiles, Membership } from '../features/Member';
import MemberPrograms from '../features/Member/MemberPrograms';
import { useScrollToTop } from '../hooks/useScrollToTop';
import {
    EditMemberAccountDetailsModal,
    EditMemberDetailsModal,
    MemberAttendanceCheckInModal,
    MemberAttendanceModal,
    MemberPerformanceModal,
    UpdateLoyaltyPointModal,
    useModal,
} from '../modals';

const Member: React.FC = () => {
    const scrollElement = useScrollToTop();
    const { toggle } = useModal();
    const [totalCount, setTotalCount] = useState(0);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/members" />
                    </IonButtons>
                    <IonTitle>Member</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => toggle('showMemberCheckIn', true)}>
                            <IonIcon slot="icon-only" icon={scanSharp} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent ref={scrollElement}>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Member</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <MemberGuard>
                    <Membership />
                    <MemberDetails />
                    <MemberPrograms />
                    <MemberRelatedProfiles />

                    <EditMemberAccountDetailsModal />
                    <EditMemberDetailsModal />
                    <MemberAttendanceModal totalCount={totalCount} setTotalCount={setTotalCount}>
                        <MemberAttendanceCheckInModal setTotalCount={setTotalCount} />
                    </MemberAttendanceModal>
                    <MemberPerformanceModal />
                    <UpdateLoyaltyPointModal />
                </MemberGuard>
            </IonContent>
        </IonPage>
    );
};

export default Member;
