/** @jsxImportSource @emotion/react */

import Storage from '@aws-amplify/storage';
import { Toast } from '@capacitor/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonButton } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { useUpdateVideoApproveRequest } from '../../hooks/useApproveVideo';
import { useOnScreen } from '../../hooks/useOnScreen';
import { useRemoveCompetitionUploadedVideo } from '../../hooks/useRemoveCompetitionUploadedVideo';
import { useAuthContext } from '../Auth';

interface CompetitionBlockProps {
    title: string;
    video: Video;
    onRemoveVideo?: (id: string) => void;
}
const CompetitionBlock: React.FC<CompetitionBlockProps> = ({ title, video, onRemoveVideo }): React.ReactElement => {
    const [isPlayingVideo, setIsPlayingVideo] = useState(false);
    const { user } = useAuthContext();
    const [url, setUrl] = useState('');
    const [useUploadVideoRequest] = useUpdateVideoApproveRequest();
    const ref = useRef<HTMLDivElement | null>(null);
    const isVisible = useOnScreen(ref);
    const [removeVideo] = useRemoveCompetitionUploadedVideo({
        onCompleted: (data) => {
            onRemoveVideo && onRemoveVideo(data.removeCompetitionUploadedVideo._id);
            Toast.show({ text: 'Delete video successfully' });
        },
        onError(err) {
            Toast.show({ text: err.message });
        },
    });
    useEffect(() => {
        if (!isVisible) {
            const videoTag: HTMLVideoElement | null | undefined = document
                .getElementById(video._id)
                ?.querySelector('video');
            if (videoTag) {
                videoTag.pause();
            }
        }
    }, [isVisible]);

    const getVideoUrl = async () => {
        try {
            const url = await Storage.get(video.videoUrl);
            setUrl(url as string);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getVideoUrl();
    }, [video]);

    const handleApprove = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>, approve: boolean) => {
        e.stopPropagation();
        if (!user?.getUsername) return;
        useUploadVideoRequest({
            variables: { id: video._id, input: { approve } },
            refetchQueries: ['Videos'],
        });
    };

    return (
        <Container ref={ref}>
            <p>{title}</p>
            <Media>
                <ReactPlayer
                    css={css`
                        height: 100% !important;
                        display: flex;
                        align-items: center;
                    `}
                    id={video._id}
                    url={url}
                    playing={isVisible ? isPlayingVideo : false}
                    controls
                    playsinline
                    onPause={() => setIsPlayingVideo(false)}
                />
            </Media>
            <ButtonGroup>
                {onRemoveVideo ? (
                    <>
                        <IonButton onClick={() => removeVideo({ variables: { id: video._id } })} expand="full">
                            Delete
                        </IonButton>
                        <IonButton href={url} target="_blank" expand="full" color="tertiary">
                            Download
                        </IonButton>
                    </>
                ) : (
                    <>
                        <IonButton onClick={(e) => handleApprove(e, true)} expand="full">
                            Approve
                        </IonButton>
                        <IonButton onClick={(e) => handleApprove(e, false)} expand="full">
                            Not Approve
                        </IonButton>
                    </>
                )}
            </ButtonGroup>
        </Container>
    );
};
const Container = styled.div`
    p {
        margin-bottom: 0;
    }
`;
const Media = styled.div`
    padding: 0.875rem 2rem;
    width: auto !important;
    height: calc(100vw / 1920 * 1080);
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    video {
        width: 100%;
        height: 100%;
    }
`;

const ButtonGroup = styled.div`
    padding: 0.125rem 2rem;
    display: flex;
    justify-content: space-between;
    ion-button {
        width: 45%;
    }
`;
export default CompetitionBlock;
