import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export enum ContentNameType {
    IMAGE = 'image',
    VIDEO = 'video',
    MP3 = 'mp3',
    BANNER = 'banner',
    COMPETITION = 'competition',
    TICKET = 'ticket',
    REPORT = 'report',
}

export const GET_CONTENT_BY_NAME = gql`
    query GetContentByName($name: String!) {
        contentByName(name: $name) {
            name
            description
        }
    }
`;

type TData = { contentByName: Content };
type TVariables = { name: ContentNameType };

export const useContentByName = (options?: QueryHookOptions<TData, TVariables>) => {
    return useQuery<TData, TVariables>(GET_CONTENT_BY_NAME, options);
};
