import { gql, useQuery } from '@apollo/client';

export const GET_LIST_COMPETITIONS = gql`
    query GetListCompetitions {
        competitions {
            _id
            name
            description
            videoDemo
            thumbnail
        }
    }
`;

export const useCompetitions = () => {
    return useQuery<{ competitions: Competition[] }>(GET_LIST_COMPETITIONS);
};
