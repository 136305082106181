import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const REMOVE_PAST_GAME = gql`
    mutation RemovePastGame($id: String!) {
        removePastGame(id: $id) {
            _id
            url
        }
    }
`;

type TData = { removePastGame: PastGame };

export const useRemovePastGame = (options?: MutationHookOptions<TData>) => {
    return useMutation<TData, { id?: string }>(REMOVE_PAST_GAME, options);
};
