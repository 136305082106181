import { gql, useMutation } from '@apollo/client';

export const useUpdateMemberRatings = () => {
    return useMutation(UPDATE_MEMBE_RATINGS);
};

const UPDATE_MEMBE_RATINGS = gql`
    mutation UpdateMemberRatings($memberId: String!, $input: UpdateMemberRatingsInput!, $year: Int!, $month: Int!) {
        updateMemberRatings(memberId: $memberId, input: $input) {
            _id
            ratings(year: $year, month: $month) {
                year
                month
                passing
                shooting
                dribbling
                gameAwareness
                score
                feedback
                report
                competitionComment
            }
        }
    }
`;
