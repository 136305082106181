import { Storage } from 'aws-amplify';
import React, { useCallback } from 'react';

const useS3Upload = ({
    uuid,
    onCompleted,
    onError,
}: {
    uuid?: string;
    onCompleted?: (s3Key: string) => void;
    onError?: (err: any) => void;
}) => {
    const handleUpload = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = (event.target.files as FileList)[0];
            if (!file) return onError && onError('Error !');
            const key = uuid || file.name;
            try {
                const result = await Storage.put(key, file);
                if ('key' in result) {
                    onCompleted && onCompleted(result['key']);
                }
            } catch (err) {
                console.log(err);
                onError && onError(err);
            }
        },
        [onCompleted, onError, uuid],
    );

    return handleUpload;
};

export default useS3Upload;
