import styled from '@emotion/styled';
import { IonRippleEffect } from '@ionic/react';
import { useField } from 'formik';
import React, { HTMLProps } from 'react';

import useS3Upload from '../../hooks/useS3Upload';
import { Upload } from '../Upload';

export type UploadInputProps = {
    name: string;
    uuid: string;
    children: React.ReactNode;
} & Pick<HTMLProps<HTMLInputElement>, 'accept'>;

const UploadInput: React.FC<UploadInputProps> = ({ uuid, name, children, ...props }) => {
    const [, , { setValue, setError }] = useField(name);
    const handleUpload = useS3Upload({
        uuid,
        onCompleted(key) {
            setValue(key);
        },
        onError(err) {
            console.log(err);
            setError('Upload failed');
        },
    });

    return (
        <Container className="ion-activatable ripple-parent">
            {children}
            <IonRippleEffect />
            <Upload type="file" onChange={handleUpload} {...props} />
        </Container>
    );
};

export default UploadInput;

const Container = styled.div`
    position: relative;
`;
