import { gql, useQuery } from '@apollo/client';

export const useRecentPaymentApprovals = () => {
    return useQuery<{ recentPaymentApprovals: PaymentApproval[] }>(RECENT_PAYMENT_APPROVALS);
};

const RECENT_PAYMENT_APPROVALS = gql`
    query RecentPaymentApprovals {
        recentPaymentApprovals {
            _id
            orderId
            memberId
            member {
                name
            }
            amount
            currency
            notes
            attachment
            status
            createdAt
            updatedAt
        }
    }
`;
