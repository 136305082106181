import styled from '@emotion/styled';
import { IonSlide, IonSlides } from '@ionic/react';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useMemo, useState } from 'react';

import { useModal } from '../../modals';
import { AttendanceContext } from '../../modals/MemberAttendanceModal';

const MemberAttendanceGrid: React.FC = () => {
    const { date, setDate } = useContext(AttendanceContext);
    const swiper = React.createRef<HTMLIonSlidesElement>();
    const slides = useMemo(() => {
        return [
            {
                id: date.subtract(1, 'month').format('YYYYMM'),
                value: date.subtract(1, 'month'),
            },
            {
                id: date.format('YYYYMM'),
                value: date,
            },
            {
                id: date.add(1, 'month').format('YYYYMM'),
                value: date.add(1, 'month'),
            },
        ];
    }, [date]);

    const initialSlide = slides.findIndex((slide) => slide.id === date.format('YYYYMM'));

    const MemoDatePicker = () =>
        useMemo(
            () => (
                <IonSlides
                    ref={swiper}
                    key={slides.map((slide) => slide.id).join('-')}
                    options={{ initialSlide }}
                    onIonSlideNextEnd={async () => {
                        if (swiper.current) {
                            const activeIndex = await swiper.current.getActiveIndex();
                            if (activeIndex !== initialSlide) {
                                setDate(date.add(1, 'month'));
                            }
                        }
                    }}
                    onIonSlidePrevEnd={async () => {
                        if (swiper.current) {
                            const activeIndex = await swiper.current.getActiveIndex();
                            if (activeIndex !== initialSlide) {
                                setDate(date.subtract(1, 'month'));
                            }
                        }
                    }}
                >
                    {slides.map((slide) => (
                        <IonSlide key={slide.id}>
                            <MonthGrid date={slide.value} />
                        </IonSlide>
                    ))}
                </IonSlides>
            ),
            [slides],
        );
    return <MemoDatePicker />;
};

const MonthGrid: React.FC<{ date: Dayjs }> = ({ date }) => {
    const { attendances } = useContext(AttendanceContext);

    const days = date.daysInMonth();
    const monthTitle = date.format('MMMM YYYY');
    const monthStartDay = date.startOf('month').day();

    return (
        <Container>
            <Title>{monthTitle}</Title>
            <DayOfWeek>
                {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, i) => (
                    <div key={i}>{day}</div>
                ))}
            </DayOfWeek>
            <WeekGrid startDay={monthStartDay}>
                {Array.from(Array(days), (_, i) => {
                    return (
                        <DateGrid
                            key={i}
                            isActive={attendances?.activities?.some((item) =>
                                dayjs(item.createdAt).isSame(date.date(i + 1), 'date'),
                            )}
                            isSession={attendances?.activities?.some(
                                (item) => dayjs(item.createdAt).isSame(date.date(i + 1), 'date') && !!item.programId,
                            )}
                            D={(i + 1).toString()}
                        />
                    );
                })}
            </WeekGrid>
        </Container>
    );
};

const DateGrid: React.FC<{ isActive?: boolean; D: string; isSession?: boolean }> = ({ isActive, D, isSession }) => {
    const { toggle } = useModal();
    const { date, setDate } = useContext(AttendanceContext);
    const [pressed, setPressed] = useState(false);

    const dt = `${date.format('YYYY-MM-')}${D}`;

    return (
        <DateGridItem
            {...{ isActive: isActive || pressed, isSession }}
            onTouchStart={(e) => {
                e.stopPropagation();
                setPressed(true);
            }}
            onTouchEnd={(e) => {
                e.stopPropagation();
                setPressed(false);
            }}
            onTouchCancel={(e) => {
                e.stopPropagation();
                setPressed(false);
            }}
            onClick={() => {
                const today = dayjs();
                const selectedDate = dayjs(dt, 'YYYY-MM-D');
                if (!isActive && today.isAfter(selectedDate)) {
                    setDate(selectedDate);
                    toggle('showMemberAttendanceCheckIn', true);
                }
            }}
        >
            <div>{D}</div>
        </DateGridItem>
    );
};

export default MemberAttendanceGrid;

const Container = styled.div`
    color: #989aa2;
    width: 100%;
    height: inherit;
    overflow: auto;
`;

const Title = styled.div`
    text-align: center;
    margin-bottom: 1rem;
`;

const DayOfWeek = styled.div`
    display: grid;
    grid-gap: 0.25rem;
    grid-template-columns: repeat(7, minmax(0, 1fr));

    & > div {
        text-align: center;
        font-size: smaller;
    }
`;

const WeekGrid = styled.div<{ startDay?: number }>`
    display: grid;
    grid-gap: 0.25rem;
    grid-template-columns: repeat(7, minmax(0, 1fr));

    & > *:first-of-type {
        grid-column-start: ${({ startDay }) => (startDay === 0 ? 7 : startDay)};
    }
`;

const DateGridItem = styled.div<{ isActive?: boolean; isSession?: boolean }>`
    width: 100%;
    display: block;
    position: relative;
    padding-bottom: 100%;
    background-color: ${({ isActive, isSession }) =>
        isActive
            ? isSession
                ? 'var(--ion-session-attendance)'
                : 'var(--ion-monthly-attendance)'
            : 'var(--ion-not-attendance)'};

    & > div {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        justify-items: flex-end;
        padding: 0 0.25rem;
        font-size: smaller;
    }
`;
