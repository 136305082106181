/** @jsxImportSource @emotion/react */

import Storage from '@aws-amplify/storage';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonButton } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { useOnScreen } from '../../hooks/useOnScreen';
import { useAuthContext } from '../Auth';
import { useUpdateTrainingVideoApproveRequest } from '../../hooks/useApproveTrainingVideo';
import { TRAINING_VIDEOS } from '../../hooks/useTrainingVideos';
interface CompetitionBlockProps {
    title: string;
    video: TrainingVideo;
    catalogueId: string;
    memberId: string;
}
const CompetitionBlock: React.FC<CompetitionBlockProps> = ({
    title,
    video,
    catalogueId,
    memberId,
}): React.ReactElement => {
    const [isPlayingVideo, setIsPlayingVideo] = useState(false);
    const { user } = useAuthContext();
    const [url, setUrl] = useState('');
    const [updateApproveRequest] = useUpdateTrainingVideoApproveRequest({
        update: (cache) => {
            const existedVideos = cache.readQuery<{ trainingVideos: TrainingVideo[] }>({
                query: TRAINING_VIDEOS,
                variables: {
                    isApproved: false,
                    catalogueId,
                    memberId,
                },
            });
            if (existedVideos) {
                cache.writeQuery<{ trainingVideos: TrainingVideo[] }>({
                    query: TRAINING_VIDEOS,
                    variables: {
                        isApproved: false,
                        catalogueId,
                        memberId,
                    },
                    data: {
                        trainingVideos: existedVideos.trainingVideos.filter(
                            (trainingVideo) => trainingVideo._id !== video._id,
                        ),
                    },
                });
            }
        },
    });
    const ref = useRef<HTMLDivElement | null>(null);
    const isVisible = useOnScreen(ref);

    useEffect(() => {
        if (!isVisible) {
            const videoTag: HTMLVideoElement | null | undefined = document
                .getElementById(video._id)
                ?.querySelector('video');
            if (videoTag) {
                videoTag.pause();
            }
        }
    }, [isVisible]);

    const getVideoUrl = async () => {
        try {
            const url = await Storage.get(video.videoUrl);
            setUrl(url as string);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getVideoUrl();
    }, [video]);

    const handleApprove = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>, approve: boolean) => {
        e.stopPropagation();
        if (!user?.getUsername) return;
        updateApproveRequest({
            variables: { id: video._id, input: { approve } },
        });
    };

    return (
        <Container ref={ref}>
            <p>{title}</p>
            <Media>
                <ReactPlayer
                    css={css`
                        height: 100% !important;
                        display: flex;
                        align-items: center;
                    `}
                    id={video._id}
                    url={url}
                    playing={isVisible ? isPlayingVideo : false}
                    controls
                    onPause={() => setIsPlayingVideo(false)}
                />
            </Media>
            <ButtonGroup>
                <IonButton onClick={(e) => handleApprove(e, true)} expand="full">
                    Approve
                </IonButton>
                <IonButton onClick={(e) => handleApprove(e, false)} expand="full">
                    Not Approve
                </IonButton>
            </ButtonGroup>
        </Container>
    );
};
const Container = styled.div`
    p {
        margin-bottom: 0;
    }
`;
const Media = styled.div`
    padding: 15px 30px;
    width: auto !important;
    height: calc(100vw / 1920 * 1080);
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    video {
        width: 100%;
        height: 100%;
    }
`;

const ButtonGroup = styled.div`
    padding: 5px 30px;
    display: flex;
    justify-content: space-between;
    ion-button {
        width: 45%;
    }
`;
export default CompetitionBlock;
