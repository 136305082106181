/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';

import BannerDropdown from '../features/Banners/BannerDropdown';
import { useBanners } from '../hooks/useBanners';
import { AddBannerModal, useModal } from '../modals';

const Banners: React.FunctionComponent = () => {
    const [banners, setBanners] = useState<Banner[]>([]);
    const { toggle } = useModal();
    useBanners({
        onCompleted: (data) => {
            setBanners(data.banners);
        },
    });

    const handleOnRemoveBanner = (bannerId: string) => {
        setBanners(banners.filter((banner) => banner._id !== bannerId));
    };
    const handleOnUpdateBanner = (updatedBanner: Banner) => {
        const updatedBanners = banners.map((banner) => {
            if (banner._id === updatedBanner._id) {
                return updatedBanner;
            }
            return banner;
        });
        setBanners(updatedBanners);
    };
    const handleOnCreateBanner = (newBanner: Banner) => {
        setBanners([...banners, newBanner]);
    };
    return (
        <IonPage>
            <AddBannerModal onCreateBanner={handleOnCreateBanner} />
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Manage Banners</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent
                css={css`
                    --padding-bottom: 2rem;
                `}
            >
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Manage Banners</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <section
                    css={css`
                        margin: 1rem 3rem;
                    `}
                >
                    <IonButton expand="block" onClick={() => toggle('showAddBannerModal')}>
                        ADD A NEW BANNER
                    </IonButton>
                </section>
                <IonList>
                    {!banners.length ? (
                        <IonItem>
                            <h1>Empty Banner</h1>
                        </IonItem>
                    ) : (
                        banners.map((banner) => (
                            <BannerDropdown
                                key={banner._id}
                                banner={banner}
                                onRemoveBanner={handleOnRemoveBanner}
                                onUpdateBanner={handleOnUpdateBanner}
                            />
                        ))
                    )}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Banners;
