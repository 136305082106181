import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const REMOVE_COMPETITION_UPLOADED_VIDEO = gql`
    mutation RemoveCompetitionUploadedVideo($id: String!) {
        removeCompetitionUploadedVideo(id: $id) {
            _id
        }
    }
`;

type TData = { removeCompetitionUploadedVideo: { _id: string } };
type TVariables = { id: string };

export const useRemoveCompetitionUploadedVideo = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(REMOVE_COMPETITION_UPLOADED_VIDEO, options);
};
