import { Toast } from '@capacitor/core';
import styled from '@emotion/styled';
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToggle,
    IonToolbar,
} from '@ionic/react';
import React, { useEffect, useMemo, useState } from 'react';

import { Upload } from '../components';
import { useAuthContext } from '../features/Auth';
import PastGamesDropdown from '../features/Settings/PastGamesDropdown';
import useS3Upload from '../hooks/useS3Upload';
import { useUploadContent } from '../hooks/useUploadContent';
import { GET_CONTENTS, useContents } from '../hooks/useContents';
import config from '../services/config';
import { ContentNameType } from '../hooks/useContentByName';
import SidebarDropdown from '../features/Settings/SidebarDropdown';

enum Segment {
    GENERAL = 'general',
    PAGE = 'Page',
}

const Settings: React.FC = () => {
    const { userAttributes } = useAuthContext();

    const [currentSegment, setCurrentSegment] = useState(Segment.GENERAL);
    const [inputUpload, setInputUpload] = useState<{ name: ContentNameType; description: string }>();
    const [youtubeUrl, setYoutubeUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [adsLink, setAdsLink] = useState<string>('');

    const [uploadUrl] = useUploadContent({
        update: (cache, { data }) => {
            const cached = cache.readQuery<{ contents: Content[] }>({
                query: GET_CONTENTS,
            });
            if (data?.uploadContent && cached) {
                const uploadContent = data.uploadContent;
                const { description, name } = uploadContent;
                const foundName = cached?.contents.find((i) => i.name === uploadContent.name);
                cache.writeQuery<{ contents: Content[] }>({
                    query: GET_CONTENTS,
                    data: {
                        contents: foundName
                            ? cached.contents.map((item) => (item.name === name ? uploadContent : item))
                            : [...cached.contents, { name, description }],
                    },
                });
            }
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
            Toast.show({ text: 'Something went wrong. Please try again' });
        },
    });

    const { data } = useContents();
    const getUploadedUrl = (name: ContentNameType) => {
        return data?.contents.find((content) => content.name === name)?.description || '';
    };
    const urlImage = getUploadedUrl(ContentNameType.IMAGE);
    // const urlMp3 = getUploadedUrl(ContentNameType.MP3);
    const checked = getUploadedUrl(ContentNameType.COMPETITION) === 'activate';

    useEffect(() => {
        if (data) {
            setYoutubeUrl(getUploadedUrl(ContentNameType.VIDEO));
            setAdsLink(getUploadedUrl(ContentNameType.BANNER));
        }
    }, [data]);

    useEffect(() => {
        if (inputUpload) {
            uploadUrl({ variables: { input: inputUpload } });
        }
    }, [inputUpload]);

    const handleActiveCompetition = () => {
        setLoading(true);
        setInputUpload({ name: ContentNameType.COMPETITION, description: checked ? 'inactivate' : 'activate' });
    };

    const handleUploadYoutubeUrl = () => {
        if (youtubeUrl !== getUploadedUrl(ContentNameType.VIDEO)) {
            setLoading(true);
            setInputUpload({ name: ContentNameType.VIDEO, description: youtubeUrl });
        }
    };

    // const handleUploadAudio = useS3Upload({
    //     onCompleted(filename) {
    //         setInputUpload({
    //             name: ContentNameType.MP3,
    //             description: filename,
    //         });
    //     },
    //     onError(err) {
    //         setLoading(false);
    //         Toast.show({ text: err.message });
    //     },
    // });

    const handleUploadImage = useS3Upload({
        onCompleted(filename) {
            setInputUpload({
                name: ContentNameType.IMAGE,
                description: filename,
            });
        },
        onError(err) {
            setLoading(false);
            Toast.show({ text: err.message });
        },
    });
    const handleUploadAdsLink = () => {
        if (adsLink !== getUploadedUrl(ContentNameType.BANNER)) {
            setInputUpload({ name: ContentNameType.BANNER, description: adsLink });
        }
    };

    const MemoToggle: React.FunctionComponent = () =>
        useMemo(
            () => <IonToggle slot="end" color="success" checked={checked} onIonChange={handleActiveCompetition} />,
            [checked],
        );

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Settings</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonLoading isOpen={loading} />
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Settings</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonSegment swipeGesture onIonChange={(e) => setCurrentSegment(e.detail.value as Segment)}>
                    <IonSegmentButton value={Segment.GENERAL}>
                        <IonLabel>General</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value={Segment.PAGE}>
                        <IonLabel>Page Settings</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                <Container>
                    {currentSegment === Segment.PAGE && (
                        <IonList>
                            <IonItem>
                                <IonLabel position="floating">Broadcast Link</IonLabel>
                                <IonInput
                                    type="text"
                                    onIonChange={(e) => setYoutubeUrl(e.detail.value || '')}
                                    value={youtubeUrl}
                                    onIonBlur={() => {
                                        handleUploadYoutubeUrl();
                                    }}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position={urlImage ? 'floating' : 'fixed'}>Ads Banner</IonLabel>
                                <IonInput value={urlImage} readonly />
                                <Upload
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        setLoading(true);
                                        handleUploadImage(e);
                                    }}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Ads Link</IonLabel>
                                <IonInput
                                    type="text"
                                    onIonChange={(e) => setAdsLink(e.detail.value || '')}
                                    value={adsLink}
                                    onIonBlur={() => {
                                        handleUploadAdsLink();
                                    }}
                                />
                            </IonItem>
                            {/* <IonItem>
                                <IonLabel position={urlMp3 ? 'floating' : 'fixed'}>Training Audio</IonLabel>
                                <IonInput value={getUploadedUrl(ContentNameType.MP3)} readonly />
                                <Upload
                                    type="file"
                                    accept="audio/*"
                                    onChange={(e) => {
                                        setLoading(true);
                                        handleUploadAudio(e);
                                    }}
                                />
                            </IonItem> */}
                            <IonListHeader />
                            <IonItem>
                                <IonLabel>Activate Competition</IonLabel>
                                <MemoToggle />
                            </IonItem>
                            <PastGamesDropdown />
                            <SidebarDropdown />
                        </IonList>
                    )}
                    {currentSegment === Segment.GENERAL && (
                        <IonList>
                            <IonItem>
                                <IonLabel position="floating">Email</IonLabel>
                                <IonInput value={userAttributes.email} readonly />
                            </IonItem>
                            <IonListHeader />
                            <IonItem href={`${config.baseUrl}/api/activities/attendances/export`}>
                                <IonLabel>Export Attendance Data</IonLabel>
                            </IonItem>
                            <IonItem href={`${config.baseUrl}/api/activities/orders/export`}>
                                <IonLabel>Export Order Data</IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonLabel>
                                    App Version
                                    <p>{process.env.REACT_APP_VERSION}</p>
                                </IonLabel>
                            </IonItem>
                            <IonListHeader />
                            <IonItem button detail={false} routerLink="/logout">
                                <IonLabel color="danger">Log out</IonLabel>
                            </IonItem>
                        </IonList>
                    )}
                </Container>
            </IonContent>
        </IonPage>
    );
};

export default Settings;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - var(--content-header-height));

    ion-text {
        margin-bottom: auto;
    }

    ion-button {
        margin-bottom: 1rem;
    }
`;
