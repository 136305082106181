import styled from '@emotion/styled';
import { IonAvatar, IonRippleEffect, IonText } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';

import { Flex } from '../../components';
import { useAvatar } from '../../hooks/useAvatar';

interface Props extends React.HTMLProps<HTMLIonAvatarElement> {
    profile: MemberProfile;
    item?: boolean;
}

const MemberAvatar: React.FC<Props> = ({ ref, profile: { _id: id, name, picture }, item, ...props }) => {
    const history = useHistory();
    const { src, alt } = useAvatar(picture);

    if (item) {
        return (
            <IonAvatar {...props}>
                <img {...{ src, alt }} />
            </IonAvatar>
        );
    }

    return (
        <Container className="ion-activatable" onClick={() => history.push(`/profile/${id}`)}>
            <IonAvatar>
                <img {...{ src, alt }} />
            </IonAvatar>
            <IonText>
                {name.split(' ').map((str, index) => (
                    <span key={index.toString()}>{str}</span>
                ))}
            </IonText>
            <IonRippleEffect />
        </Container>
    );
};

export default MemberAvatar;

const Container = styled(Flex)`
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 1rem;
`;
