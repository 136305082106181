import { useParams } from 'react-router-dom';

import { useMemberProfile } from '../../hooks/useMemberProfile';

/**
 * Use in conjunction with MemberGuard to access member context
 */
const useMember = () => {
    const { id } = useParams<{ id: string }>();
    const { data } = useMemberProfile(id);

    if (!data?.member) throw new Error('useMember must be used inside <MemberGuard />');

    return data.member;
};

export default useMember;
