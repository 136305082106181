import styled from '@emotion/styled';
import { IonButton, IonIcon, IonItem, IonItemGroup } from '@ionic/react';
import { addCircleSharp, chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import React, { useState } from 'react';

import { useSidebar } from '../../hooks/useSidebar';
import { AddSidebarModal, useModal } from '../../modals';

import SidebarDetail from './SidebarDetail';

const SidebarDropdown = () => {
    const [isDropped, setIsDropped] = useState(false);
    const [sidebars, setSidebars] = useState<Sidebar[]>([]);
    const { toggle } = useModal();

    useSidebar({
        onCompleted(data) {
            setSidebars(data.sidebars);
        },
    });

    const handleOnCreateSidebar = (newSidebar: Sidebar) => {
        setSidebars([...sidebars, newSidebar]);
    };
    const handleOnRemoveSidebar = (id: string) => {
        setSidebars(sidebars.filter((sidebar) => sidebar._id !== id));
    };

    const handleOnUpdateSidebar = (updatedSidebar: Sidebar) => {
        const updatedSidebars = sidebars.map((sidebar) => {
            if (sidebar._id === updatedSidebar._id) {
                return updatedSidebar;
            }
            return sidebar;
        });
        setSidebars(updatedSidebars);
    };

    return (
        <IonItemGroup>
            <AddSidebarModal onCreateSidebar={handleOnCreateSidebar} />
            <HeaderItem className="ion-activatable ripple-parent" onClick={() => setIsDropped(!isDropped)}>
                <div>Sidebar</div>
                <IonIcon size="small" color="sliver" icon={isDropped ? chevronUpOutline : chevronDownOutline} />
            </HeaderItem>

            {isDropped && (
                <DropdownContent>
                    <AddButton expand="block" fill="clear" onClick={() => toggle('showAddSidebarModal')}>
                        Add item to sidebar
                        <IonIcon icon={addCircleSharp} slot="icon-only" />
                    </AddButton>
                    {!sidebars.length ? (
                        <IonItem lines="none">Empty Sidebar</IonItem>
                    ) : (
                        sidebars.map((sidebar) => (
                            <SidebarDetail
                                key={sidebar._id}
                                sidebar={sidebar}
                                onRemoveSidebar={handleOnRemoveSidebar}
                                onUpdateSidebar={handleOnUpdateSidebar}
                            />
                        ))
                    )}
                </DropdownContent>
            )}
        </IonItemGroup>
    );
};

export default SidebarDropdown;

const HeaderItem = styled(IonItem)`
    div {
        width: 100%;
    }

    ion-icon {
        float: right;
    }
`;

const DropdownContent = styled.div`
    margin-left: 1rem;
`;

const AddButton = styled(IonButton)`
    margin-bottom: 0 !important;
`;
