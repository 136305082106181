import styled from '@emotion/styled';
import {
    IonDatetime,
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
} from '@ionic/react';
import dayjs from 'dayjs';
import { chevronDownOutline, chevronUpOutline, trash } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';

import { useRemoveProgram } from '../../hooks/useRemoveProgram';
import { useUpdateProgram } from '../../hooks/useUpdateProgram';
import { ToastProps } from '../../pages/ManagePrograms';

interface ProgramDropdownProps {
    program: Program;
    setIsOpenLoading: React.Dispatch<React.SetStateAction<boolean>>;
    onRemoveProgram: (programId: string) => void;
    onUpdateProgram: (program: Program) => void;
    setToast: React.Dispatch<React.SetStateAction<ToastProps>>;
}

const ProgramDropdown: React.FunctionComponent<ProgramDropdownProps> = ({
    program,
    setToast,
    onRemoveProgram,
    onUpdateProgram,
    setIsOpenLoading,
}) => {
    const [isDropped, setIsDropped] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState(program.startDate || '');
    const [selectedEndDate, setSelectedEndDate] = useState(program.endDate || '');
    const [selectedStartTime, setSelectedStartTime] = useState(program.startTime || '');
    const [selectedEndTime, setSelectedEndTime] = useState(program.endTime || '');
    const [linkValue, setLinkValue] = useState(program.link || '');
    const [nameValue, setNameValue] = useState(program.name || '');
    const [addressValue, setAddressValue] = useState(program.address || '');
    const [feeValue, setFeeValue] = useState(program.fee || 0);

    const [removeProgram] = useRemoveProgram({
        onCompleted: (data) => {
            onRemoveProgram(data.removeProgram._id);
        },
        onError: () => {
            setToast({ isOpen: true, message: 'Something went wrong. Please try again', color: 'primary' });
        },
    });

    const [updateProgram, { loading: updateProgramLoading }] = useUpdateProgram({
        onCompleted: (data) => {
            onUpdateProgram(data.updateProgram);
            setToast({ isOpen: true, message: 'Updated Successfully', color: 'success' });
        },
        onError: () => {
            setToast({ isOpen: true, message: 'Something went wrong. Please try again', color: 'primary' });
        },
    });

    const handleOnRemoveProgram = () => {
        removeProgram({ variables: { id: program._id } });
    };

    const handleOnUpdateProgram = (key: string, value: string) => {
        if (!value.length) {
            return setToast({ isOpen: true, message: 'Fields are required', color: 'primary' });
        }
        if (value === program[key as keyof Program]) {
            return;
        }
        const startMoment = dayjs(`${program.startDate || dayjs().format('YYYY-MM-DD')} ${program.startTime}`);
        const endMoment = dayjs(`${program.endDate || dayjs().format('YYYY-MM-DD')} ${program.endTime}`);
        if (endMoment.isSameOrBefore(startMoment)) {
            return setToast({ isOpen: true, message: 'Date is invalid', color: 'primary' });
        }
        updateProgram({
            variables: {
                id: program._id,
                input: { key, value: key.includes('Date') ? dayjs(value).format('YYYY-MM-DD') : value },
            },
        });
    };

    useEffect(() => {
        setIsOpenLoading(updateProgramLoading);
    }, [updateProgramLoading]);

    return (
        <IonItemGroup>
            <IonItemSliding>
                <IonItemOptions side="end">
                    <IonItemOption onClick={handleOnRemoveProgram}>
                        <IonIcon icon={trash} slot="icon-only" />
                    </IonItemOption>
                </IonItemOptions>
                <HeaderItem className="ion-activatable ripple-parent" onClick={() => setIsDropped(!isDropped)}>
                    <div>{program.name}</div>
                    <IonIcon size="small" color="sliver" icon={isDropped ? chevronUpOutline : chevronDownOutline} />
                </HeaderItem>
            </IonItemSliding>

            {isDropped && (
                <ContentContainer>
                    <IonItem>
                        <IonLabel>Name</IonLabel>
                        <IonInput
                            value={nameValue}
                            onIonChange={(e) => setNameValue(e.detail.value!)}
                            onIonBlur={(e) => {
                                handleOnUpdateProgram('name', nameValue);
                            }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Link</IonLabel>
                        <IonInput
                            value={linkValue}
                            onIonChange={(e) => setLinkValue(e.detail.value!)}
                            onIonBlur={() => handleOnUpdateProgram('link', linkValue)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Start Date</IonLabel>
                        <IonDatetime
                            displayFormat="MM-DD-YYYY"
                            value={selectedStartDate}
                            onIonChange={(e) => {
                                if (e.detail.value) {
                                    setSelectedStartDate(e.detail.value);
                                    handleOnUpdateProgram('startDate', e.detail.value);
                                }
                            }}
                            min={dayjs().format('YYYY-MM-DD')}
                            max={selectedEndDate}
                        ></IonDatetime>
                    </IonItem>
                    <IonItem>
                        <IonLabel>End Date</IonLabel>
                        <IonDatetime
                            displayFormat="MM-DD-YYYY"
                            value={selectedEndDate}
                            onIonChange={(e) => {
                                if (e.detail.value) {
                                    setSelectedEndDate(e.detail.value);
                                    handleOnUpdateProgram('endDate', e.detail.value);
                                }
                            }}
                            min={selectedStartDate}
                            max={dayjs(selectedEndDate).add(10, 'years').format('YYYY-MM-DD')}
                        ></IonDatetime>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Start Time</IonLabel>
                        <IonDatetime
                            displayFormat="hh:mm A"
                            value={selectedStartTime}
                            onIonChange={(e) => {
                                if (e.detail.value) {
                                    setSelectedStartTime(e.detail.value);
                                    handleOnUpdateProgram('startTime', e.detail.value);
                                }
                            }}
                        ></IonDatetime>
                    </IonItem>
                    <IonItem>
                        <IonLabel>End Time</IonLabel>
                        <IonDatetime
                            displayFormat="hh:mm A"
                            value={selectedEndTime}
                            onIonChange={(e) => {
                                if (e.detail.value) {
                                    setSelectedEndTime(e.detail.value);
                                    handleOnUpdateProgram('endTime', e.detail.value);
                                }
                            }}
                        ></IonDatetime>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Venue Address</IonLabel>
                        <IonInput
                            value={addressValue}
                            onIonChange={(e) => setAddressValue(e.detail.value!)}
                            onIonBlur={() => handleOnUpdateProgram('address', addressValue)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Program Fees</IonLabel>
                        <IonInput
                            value={feeValue}
                            onIonChange={(e) =>
                                setFeeValue(
                                    e.detail.value && parseFloat(e.detail.value) >= 1 ? parseFloat(e.detail.value) : 1,
                                )
                            }
                            onIonBlur={() => handleOnUpdateProgram('fee', feeValue.toString())}
                            type="number"
                            min="1"
                            step="0.25"
                        />
                        $
                    </IonItem>
                </ContentContainer>
            )}
        </IonItemGroup>
    );
};

export default ProgramDropdown;

const HeaderItem = styled(IonItem)`
    div {
        width: 100%;
        font-size: 17px;
        font-weight: 700;
    }
    ion-icon {
        float: right;
    }
`;

const ContentContainer = styled.div`
    p {
        margin-left: 2rem;
    }
    ion-input {
        text-align: right;
    }
`;
