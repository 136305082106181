import styled from '@emotion/styled';
import React from 'react';

import clubLogo1 from '../assets/SIBK_CLUB_PARTNER.png';
import clubLogo2 from '../assets/Wonderstick.png';
import clubLogo4 from '../assets/YFL.png';
import clubLogo3 from '../assets/YFS.jpg';

export const Affiliates = () => {
    return (
        <Container>
            <section>
                <h5>Official International Club Partner</h5>

                <Main>
                    <MainLogo src={clubLogo1} alt="sibk club" />
                </Main>
            </section>

            <section>
                <h5>YFA Brands</h5>

                <Sub>
                    <SubLogo src={clubLogo2} alt="bws FOURgoldstars" />
                    <SubLogo src={clubLogo3} alt="YFS.COM" />
                    <SubLogo src={clubLogo4} alt="YFL" />
                </Sub>
            </section>
        </Container>
    );
};

const Container = styled.div`
    text-align: center;
`;

const Main = styled.div``;

const MainLogo = styled.img`
    width: 96px;
    height: 96px;
    object-fit: contain;
`;

const Sub = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

const SubLogo = styled.img`
    width: 64px;
    height: 64px;
    object-fit: contain;
`;
