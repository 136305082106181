import { Reference } from '@apollo/client';
import { Plugins } from '@capacitor/core';
import styled from '@emotion/styled';
import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
} from '@ionic/react';
import { Storage } from 'aws-amplify';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import useApprovePaymentRequest from '../../hooks/useApprovePaymentApprovalRequest';
import { MEMBER_PROFILE } from '../../hooks/useMemberProfile';
import { PROGRAM_DETAIL } from '../../hooks/useProgramsDetail';
import { useRequestRemainderAmount } from '../../hooks/useRequestRemainderAmount';
import { MemberProgram } from '../Member/MemberPrograms';

const { Toast } = Plugins;

const ApprovalCard: React.FC<{
    data: PaymentApproval;
    setIsSendingRequest: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ data, setIsSendingRequest }) => {
    const [isOpenAlert, setIsOpenAlert] = useState(false);

    const [reportAmount, { loading }] = useRequestRemainderAmount({
        onCompleted: () => {
            Toast.show({ text: 'Reported Successful' });
        },
        onError: () => {
            Toast.show({ text: 'Something went wrong. Please try again' });
        },
    });
    const [approve] = useApprovePaymentRequest({
        update(cache) {
            cache.modify({
                fields: {
                    pendingPaymentApprovals(refs, { readField }) {
                        return refs.filter((ref: Reference) => data._id !== readField('_id', ref));
                    },
                },
            });
            const existedMember = cache.readQuery<{ member: MemberProfile }>({
                query: MEMBER_PROFILE,
                variables: { id: data.memberId },
            });
            if (existedMember) {
                cache.writeQuery<{ member: MemberProfile }>({
                    query: MEMBER_PROFILE,
                    data: {
                        member: {
                            ...existedMember.member,
                            loyaltyPoint: (existedMember.member.loyaltyPoint || 0) + data.amount,
                        },
                    },
                });
            }
        },
        onCompleted() {
            Toast.show({ text: 'Approved.' });
        },
        refetchQueries: ['RecentPaymentApprovals', { query: PROGRAM_DETAIL, variables: { id: data.memberId } }],
    });

    useEffect(() => {
        setIsSendingRequest(loading);
    }, [loading]);

    const handleOnSendReportAmount = () => {
        reportAmount({
            variables: {
                id: data._id,
            },
        });
    };

    const handleOnApproved = () => {
        approve({
            variables: {
                id: data._id,
                input: {
                    programs: data.programs.map((program) => {
                        const { quantity, programId } = program;
                        return {
                            programId,
                            quantity,
                        };
                    }),
                },
            },
        });
    };

    return (
        <IonCard>
            <IonAlert
                isOpen={isOpenAlert}
                onDidDismiss={() => setIsOpenAlert(false)}
                header="Are you sure to send report to Member?"
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'Send',
                        handler: handleOnSendReportAmount,
                    },
                ]}
            />
            <IonItemSliding>
                <IonItem>
                    <IonCardHeader>
                        <Meta>{dayjs(data.createdAt).fromNow()}</Meta>
                        <IonCardSubtitle>{data.member.name}</IonCardSubtitle>
                        <IonCardTitle className="font-semibold">${data.amount}</IonCardTitle>
                    </IonCardHeader>
                    {data.status === 'PENDING' && (
                        <IonButton fill="solid" slot="end" onClick={() => setIsOpenAlert(true)}>
                            Report Amount
                        </IonButton>
                    )}
                    <IonButton
                        fill="solid"
                        slot="end"
                        onClick={() => {
                            Storage.get(data.attachment).then((signedURL) => {
                                window.open(signedURL as string);
                            });
                        }}
                    >
                        View
                    </IonButton>
                </IonItem>

                <IonItemOptions
                    side="end"
                    onIonSwipe={(e) => {
                        if ('side' in e.detail && e.detail.side === 'end') {
                            handleOnApproved();
                        }
                    }}
                >
                    <IonItemOption color="success" expandable onClick={handleOnApproved}>
                        Approve
                    </IonItemOption>
                </IonItemOptions>
            </IonItemSliding>
        </IonCard>
    );
};

export default ApprovalCard;

const Meta = styled.span`
    font-size: small;
    color: var(--ion-color-medium);
`;
