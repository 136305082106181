import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export interface CompetitionFields {
    name: string;
}

const CREATE_COMPETITION = gql`
    mutation CreateCompetition($input: CreateCompetitionInput!) {
        createCompetition(input: $input) {
            _id
            name
            description
        }
    }
`;

type TData = { createCompetition: Competition };
type TVariables = { input: CompetitionFields };

export const useCreateCompetition = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(CREATE_COMPETITION, options);
};
