import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';

type TData = { trainingVideos: TrainingVideo[] };
type TVariables = { isApproved: boolean; catalogueId: string; memberId: string };

export const useTrainingVideos = (options?: QueryHookOptions<TData, TVariables>) => {
    return useLazyQuery<TData, TVariables>(TRAINING_VIDEOS, options);
};

export const TRAINING_VIDEOS = gql`
    query TrainingVideos($isApproved: Boolean!, $catalogueId: String!, $memberId: String!) {
        trainingVideos(isApproved: $isApproved, catalogueId: $catalogueId, memberId: $memberId) {
            _id
            memberId {
                _id
                name
            }
            videoUrl
            catalogueId
        }
    }
`;
