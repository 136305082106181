import { gql, MutationHookOptions, useMutation } from '@apollo/client';

interface ApprovalProgramInput {
    programId: string;
    quantity: number;
}

const UPDATE_PROGRAM_QUANTITY = gql`
    mutation UpdateProgramQuantity($id: String!, $program: ApprovalProgramInput!) {
        updateProgramQuantity(id: $id, program: $program) {
            _id
            name
        }
    }
`;

type TVariables = { id: string; program: ApprovalProgramInput };
type TData = { _id: string; name: string };

export const useUpdateProgramQuantity = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(UPDATE_PROGRAM_QUANTITY, options);
};
