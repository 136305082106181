import styled from '@emotion/styled';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    IonPage,
    IonSearchbar,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { scanSharp } from 'ionicons/icons';
import last from 'lodash/last';
import React, { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';

import { MembersFilter, MembersItem, MembersList } from '../features/Member';
import { useMembers } from '../hooks/useMembers';
import { useModal } from '../modals';

export interface FilterState {
    age: string[];
    gender: string[];
    numTokensLt5: boolean;
}

const initialState: {
    searchTerm?: string;
    filter?: FilterState;
} = {};

const PAGE_SIZE = 15;

const Members: React.FC = () => {
    const { toggle } = useModal();
    const [variables, setVariables] = useImmer(initialState);
    const [cursor, setCursor] = useState<string | undefined>();
    const [fetchData, { data, loading, fetchMore }] = useMembers({
        onCompleted({ members }) {
            if (members.length >= PAGE_SIZE) {
                setCursor(last(members)?._id);
            }
        },
    });

    useEffect(() => {
        fetchData({ variables });
    }, [fetchData, variables]);

    return (
        <PageContainer>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Member List</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => toggle('showMemberCheckIn', true)}>
                            <IonIcon slot="icon-only" icon={scanSharp} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Member List</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonToolbar>
                    <IonSearchbar
                        onIonChange={(e) =>
                            setVariables((draft) => {
                                draft.searchTerm = e.detail.value;
                            })
                        }
                    />
                    <div slot="end">
                        <MembersFilter
                            value={variables.filter}
                            handleSubmit={(value) => {
                                setVariables((draft) => {
                                    draft.filter = value;
                                });
                            }}
                        />
                    </div>
                </IonToolbar>
                {loading ? (
                    <IonList>
                        {new Array(10).fill(null).map((_, index) => (
                            <MembersItem.Placeholder key={index.toString()} />
                        ))}
                    </IonList>
                ) : (
                    <MembersList data={data?.members || []} />
                )}
                <IonInfiniteScroll
                    threshold="100px"
                    onIonInfinite={async (e) => {
                        if (!loading && cursor && fetchMore) {
                            await fetchMore({
                                variables: { cursor },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                    setCursor(last(fetchMoreResult?.members)?._id);
                                    if (!fetchMoreResult) {
                                        return prev;
                                    }
                                    return Object.assign({}, prev, {
                                        members: [...prev.members, ...fetchMoreResult.members],
                                    });
                                },
                            });
                        }
                        (e.target as any).complete();
                    }}
                >
                    <IonInfiniteScrollContent />
                </IonInfiniteScroll>
            </IonContent>
        </PageContainer>
    );
};

export default Members;

const PageContainer = styled(IonPage)`
    ion-header ion-toolbar {
        --border-width: 0 !important;
    }

    ion-searchbar.ios {
        --color: var(--ion-color-primary-contrast);
        --cancel-button-color: var(--ion-color-primary-contrast);
        --clear-button-color: var(--ion-color-primary-contrast);
        --icon-color: var(--ion-color-primary-contrast);
    }
`;
