import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const UPDATE_BANNER = gql`
    mutation UpdateBanner($id: String!, $input: UpdateBannerInput!) {
        updateBanner(id: $id, input: $input) {
            _id
            title
            subtitle
            link
            imageUrl
            isActive
        }
    }
`;

type TData = { updateBanner: Banner };
type TVariables = { id: string; input: { key: string; value: string } };

export const useUpdateBanner = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(UPDATE_BANNER, options);
};
