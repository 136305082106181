/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonIcon, IonModal, IonText } from '@ionic/react';
import dayjs from 'dayjs';
import { reorderTwoOutline } from 'ionicons/icons';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { MemberAttendanceGrid, useMember } from '../features/Member';
import { useMemberProfileAttendances } from '../hooks/useMemberProfileAttendances';

import { useModal } from '.';

export const AttendanceContext = React.createContext<{
    date: dayjs.Dayjs;
    setDate: Dispatch<SetStateAction<dayjs.Dayjs>>;
    attendances: MemberProfileAttendances | null;
}>({
    attendances: null,
    date: dayjs(),
    setDate: () => {},
});

const ProfileAttendanceModal: React.FC<{
    totalCount: number;
    setTotalCount: React.Dispatch<React.SetStateAction<number>>;
}> = ({ children, totalCount, setTotalCount }) => {
    const { _id: id } = useMember();
    const [date, setDate] = useState(dayjs());

    const { data } = useMemberProfileAttendances(id, date.year(), date.month());
    const { toggle, showMemberAttendances } = useModal();

    useEffect(() => {
        if (data) {
            setTotalCount(Math.max(data.member.attendances.totalCount, totalCount));
        }
    }, [data]);

    return (
        <AttendanceContext.Provider
            value={{
                attendances: data ? data.member.attendances : null,
                date,
                setDate,
            }}
        >
            <Container
                swipeToClose
                isOpen={showMemberAttendances}
                onDidDismiss={() => toggle('showMemberAttendances')}
                backdropDismiss
            >
                <Handle>
                    <IonIcon icon={reorderTwoOutline} />
                </Handle>

                <ContentContainer>
                    <MemberAttendanceGrid />

                    <div
                        css={css`
                            margin-top: auto;
                        `}
                    >
                        <IonText>
                            <div>
                                <strong>This Month:</strong> {data?.member.attendances.monthlyCount}
                                <span
                                    css={css`
                                        float: right;
                                    `}
                                >
                                    Session <SessionColor />
                                </span>
                            </div>
                        </IonText>

                        <IonText>
                            <p>
                                <strong>Total Since First Class:</strong> {totalCount}
                                <span
                                    css={css`
                                        float: right;
                                    `}
                                >
                                    Monthly <MonthlyColor />
                                </span>
                            </p>
                        </IonText>
                    </div>
                </ContentContainer>
            </Container>
            {children}
        </AttendanceContext.Provider>
    );
};

export default ProfileAttendanceModal;

const Container = styled(IonModal)`
    color: var(--ion-text-color);

    .modal-wrapper[role='dialog'] {
        margin-top: auto;
        height: 640px;
        border-radius: 20px;
    }

    @media only screen and (max-width: 600px) {
        .modal-wrapper[role='dialog'] {
            height: 520px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
`;

const ContentContainer = styled.div`
    height: 100%;
    padding: 1rem;

    overflow: auto;
    display: flex;
    flex-direction: column;
    min-width: 0;

    ion-slides {
        width: 100%;
    }

    ion-slide {
        align-items: flex-start;
    }
`;

const Handle = styled.div`
    text-align: center;
    padding: 1rem;
`;

const MonthlyColor = styled.span`
    padding: 3px 20px;
    margin-left: 8px;
    background-color: var(--ion-monthly-attendance);
`;
const SessionColor = styled.span`
    padding: 3px 20px;
    background-color: var(--ion-session-attendance);
    margin-left: 8px;
`;
