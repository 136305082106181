import styled from '@emotion/styled';
import { IonAvatar, IonLabel, IonList, IonListHeader, IonRippleEffect, IonText } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAvatar } from '../../hooks/useAvatar';

import useMember from './useMember';

const MemberItem: React.FC<{
    active?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    profile: MemberProfile;
}> = ({ active, onClick, profile: { name, picture } }) => {
    const { src, alt } = useAvatar(picture);

    return (
        <Item className="ion-activatable" {...{ active, onClick }}>
            <IonAvatar>
                <img {...{ src, alt }} />
            </IonAvatar>
            <IonText>
                <span>{name}</span>
            </IonText>
            <IonRippleEffect />
        </Item>
    );
};

const MemberRelatedProfiles: React.FC = () => {
    const history = useHistory();
    const { _id: id, relatedProfiles } = useMember();

    if (!relatedProfiles.length) return null;

    return (
        <IonList>
            <IonListHeader>
                <IonLabel>Related Members</IonLabel>
            </IonListHeader>

            <List>
                {relatedProfiles.map((profile) => (
                    <MemberItem
                        key={profile._id}
                        active={profile._id === id}
                        onClick={() => history.replace(`/members/${profile._id}`)}
                        {...{ profile }}
                    />
                ))}
            </List>
        </IonList>
    );
};

export default MemberRelatedProfiles;

const List = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    overflow-x: auto;
`;

const Item = styled.div<{ active?: boolean }>`
    padding: 0.25rem;
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 0.25rem;
    justify-items: center;

    ion-text {
        font-size: small;
        text-align: center;
    }

    ion-avatar {
        border: ${({ active }) => active && `2px solid var(--ion-color-primary)`};
    }
`;
