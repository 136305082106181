/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { IonButton, IonIcon, IonList, IonListHeader, IonModal } from '@ionic/react';
import { reorderTwoOutline } from 'ionicons/icons';
import { Formik } from 'formik';
import { Toast } from '@capacitor/core';

import { Field, FieldColumnProps, Flex } from '../components';
import { useCreateSidebar, CreateSidebarInput } from '../hooks/useCreateSidebar';

import { useModal } from '.';

const AddSidebarModal: React.FC<{ onCreateSidebar: (newSidebar: Sidebar) => void }> = ({ onCreateSidebar }) => {
    const { toggle, showAddSidebarModal } = useModal();
    const fields = useMemo(() => {
        const SIDEBAR_FIELDS: FieldColumnProps<keyof CreateSidebarInput>[] = [
            {
                name: 'title',
                label: 'Title*',
                variant: 'textInput',
            },
            {
                name: 'link',
                label: 'Link*',
                variant: 'textInput',
            },
        ];
        return SIDEBAR_FIELDS.map(({ name, ...props }) => <Field key={name} {...{ name }} {...props} />);
    }, []);

    const [createSidebar] = useCreateSidebar({
        onCompleted(data) {
            toggle('showAddSidebarModal', false);
            Toast.show({ text: 'Sidebar item created!' });
            onCreateSidebar(data.createSidebar);
        },
        onError(err) {
            Toast.show({ text: err.message });
        },
    });
    return (
        <Container
            swipeToClose
            isOpen={showAddSidebarModal}
            onDidDismiss={() => toggle('showAddSidebarModal', false)}
            backdropDismiss
        >
            <Handle>
                <IonIcon icon={reorderTwoOutline} />
            </Handle>
            <Formik
                validateOnBlur
                initialValues={{
                    title: '',
                    link: '',
                }}
                onSubmit={(values) => {
                    for (const [, value] of Object.entries(values)) {
                        if (!value.length) {
                            Toast.show({ text: 'Please fill out all required fields' });
                            return;
                        }
                    }
                    createSidebar({ variables: { input: { ...values } } });
                }}
            >
                {({ handleSubmit }) => (
                    <ContentContainer>
                        <IonList>
                            <IonListHeader>Add item to sidebar</IonListHeader>

                            {fields}
                        </IonList>

                        <IonButton onClick={() => handleSubmit()}>Save</IonButton>
                        <IonButton fill="outline" onClick={() => toggle('showAddSidebarModal', false)}>
                            Cancel
                        </IonButton>
                    </ContentContainer>
                )}
            </Formik>
        </Container>
    );
};

export default AddSidebarModal;

const Container = styled(IonModal)`
    color: var(--ion-text-color);

    .modal-wrapper[role='dialog'] {
        margin-top: auto;
        height: 640px;
        border-radius: 20px;
    }

    @media only screen and (max-width: 600px) {
        .modal-wrapper[role='dialog'] {
            height: 520px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .ion-page {
        justify-content: normal;
    }
`;

const Handle = styled.div`
    text-align: center;
    padding: 1rem;
`;

const ContentContainer = styled(Flex)`
    height: 100%;
    padding: 1rem;

    ion-button:first-of-type {
        margin-top: auto;
    }
`;
