/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { scanSharp } from 'ionicons/icons';
import React from 'react';

import { Affiliates, Flex, Logo } from '../components';
import { useModal } from '../modals';

const Home: React.FC = () => {
    const { toggle } = useModal();
    return (
        <Container>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Home</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => toggle('showMemberCheckIn', true)}>
                            <IonIcon slot="icon-only" icon={scanSharp} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent
                css={css`
                    height: 100%;
                `}
            >
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Home</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <ContentContainer>
                    <Logo size={128} />

                    <Flex>
                        <IonButton routerLink="/members">Members</IonButton>
                        {/* <IonButton routerLink="/tickets">Tickets</IonButton> */}
                        <IonButton routerLink="/approvals">Approvals</IonButton>
                        <IonButton routerLink="/online-competitions">Online Competitions</IonButton>
                        <IonButton routerLink="/training-catalogues">Training Catalogues</IonButton>
                        <IonButton routerLink="/banners">Banners</IonButton>
                        <IonButton routerLink="/manage-programs">Manage Programs</IonButton>
                        <IonButton routerLink="/settings">Settings</IonButton>
                    </Flex>

                    <Affiliates />
                </ContentContainer>
            </IonContent>
        </Container>
    );
};

export default Home;

const Container = styled(IonPage)``;

const ContentContainer = styled(Flex)`
    margin: auto 1rem;
    justify-content: space-around;

    ${Logo} {
        margin: 0 auto;
    }

    ion-button {
        font-size: small;
        text-transform: uppercase;
    }
`;
