import { IonLoading, IonPage } from '@ionic/react';
import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { useAuthContext } from '.';

const PageRedirect: React.FC<{
    to: {
        pathname: string;
        state?: Record<string, unknown>;
    };
}> = ({ to }) => {
    // Temporary fix for using Redirect results in infinite renders using Ionic
    useEffect(() => {
        window.location.href = to.pathname;
    }, [to]);

    return (
        <IonPage>
            <IonLoading isOpen />
        </IonPage>
    );
};

const PrivateRoute: React.SFC<RouteProps> = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useAuthContext();

    if (!Component) {
        return null;
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated === true ? (
                    <Component {...props} />
                ) : (
                    <PageRedirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
