import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Auth from '@aws-amplify/auth';

import config from './config';

const httpLink = createHttpLink({
    uri: config.baseUrl + '/graphql',
});

const authLink = setContext(async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    return { headers: { authorization: `Bearer ${token}` } };
});

export const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Member: {
                fields: {
                    progress: {
                        merge: false,
                    },
                    ratings: {
                        keyArgs: ['year', 'month'],
                        merge: false,
                    },
                },
            },
        },
    }),
    link: authLink.concat(httpLink),
});
