import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const REMOVE_BANNER = gql`
    mutation RemoveBanner($id: String!) {
        removeBanner(id: $id) {
            _id
        }
    }
`;

type TData = { removeBanner: { _id: string } };
type TVariables = { id: string };

export const useRemoveBanner = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(REMOVE_BANNER, options);
};
