import { gql, MutationHookOptions, useMutation } from '@apollo/client';

interface UpdateTrainingVideoApprovedInput {
    approve: boolean;
}

export const UPDATE_TRAINING_VIDEO_APPROVED_REQUEST = gql`
    mutation UpdateTrainingVideoApproved($id: String!, $input: UpdateTrainingVideoApprovedInput!) {
        updateTrainingVideoApproved(id: $id, input: $input) {
            _id
        }
    }
`;

type TData = { updateTrainingVideoApproved: { _id: string } };
type TVariables = { id: string; input: UpdateTrainingVideoApprovedInput };

export const useUpdateTrainingVideoApproveRequest = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, { id: string; input: UpdateTrainingVideoApprovedInput }>(
        UPDATE_TRAINING_VIDEO_APPROVED_REQUEST,
        options,
    );
};
