import styled from '@emotion/styled';
import { IonButton, IonContent, IonIcon, IonModal, IonSpinner, IonText } from '@ionic/react';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { checkmarkCircle, closeCircle } from 'ionicons/icons';
import React, { useState } from 'react';
import QrReader from 'react-qr-reader';

import { useCheckInMutation } from '../hooks/useCheckInMutation';

import { useModal } from '.';

// 24 character hex string
const isValid: (data: string) => boolean = (data) => {
    return data.length === 24;
};

const MemberCheckInModal: React.FC = () => {
    const { toggle, showMemberCheckIn } = useModal();

    const [showResults, setShowResults] = useState<boolean>(false);

    const [checkIn, { loading, error }] = useCheckInMutation({
        errorPolicy: 'all',
        onCompleted() {},
        onError() {},
    });

    return (
        <Container
            isOpen={showMemberCheckIn}
            backdropDismiss
            swipeToClose
            onDidDismiss={() => toggle('showMemberCheckIn', false)}
        >
            <IonContent>
                <AnimatePresence>
                    {showResults ? (
                        <ContentContainer>
                            <ResultsContainer>
                                {loading ? (
                                    <IonSpinner />
                                ) : error ? (
                                    <>
                                        <IonIcon color="danger" icon={closeCircle} />
                                        <IonText>
                                            <h2>Error</h2>
                                            {error.graphQLErrors.map((err, i) => (
                                                <p key={i}>{err.message}</p>
                                            ))}
                                        </IonText>
                                        <IonButton expand="block" onClick={() => setShowResults(false)}>
                                            Back
                                        </IonButton>
                                    </>
                                ) : (
                                    <>
                                        <IonIcon color="success" icon={checkmarkCircle} />
                                        <IonText>
                                            <h2>Success</h2>
                                            <p>Attendance is taken successfully.</p>
                                        </IonText>
                                        <IonButton expand="block" onClick={() => setShowResults(false)}>
                                            Back
                                        </IonButton>
                                    </>
                                )}
                            </ResultsContainer>
                        </ContentContainer>
                    ) : (
                        <ContentContainer>
                            <QrReader
                                onScan={(id) => {
                                    if (id && isValid(id)) {
                                        setShowResults(true);
                                        checkIn({
                                            variables: {
                                                memberId: id,
                                                date: dayjs().format('YYYY-MM-D'),
                                                programId: '',
                                            },
                                        });
                                    }
                                }}
                                onError={console.error}
                            />
                            <IonButton
                                onClick={() => toggle('showMemberCheckIn', false)}
                                routerLink="/members"
                                fill="clear"
                                expand="block"
                            >
                                Search Manually
                            </IonButton>
                        </ContentContainer>
                    )}
                </AnimatePresence>
            </IonContent>
        </Container>
    );
};

export default MemberCheckInModal;

const Container = styled(IonModal)`
    .modal-wrapper[role='dialog'] {
        height: 95vh;
        margin-top: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
`;

const ContentContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
`;

const ResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1rem;

    ion-icon {
        margin: 0 auto;
        font-size: 128px;
    }

    ion-spinner {
        margin: 0 auto;
        width: 100px;
        height: 100px;
    }
`;
