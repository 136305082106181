import Amplify from 'aws-amplify';

Amplify.configure({
    Auth: {
        identityPoolId: process.env.REACT_APP_STAFF_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_STAFF_REGION,
        userPoolId: process.env.REACT_APP_STAFF_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_STAFF_USER_POOL_WEB_CLIENT_ID,
    },
    Storage: {
        AWSS3: {
            region: process.env.REACT_APP_STAFF_REGION,
            bucket: process.env.REACT_APP_STAFF_S3_BUCKET,
        },
    },
});
